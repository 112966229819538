import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AboutUsObject {
  id: number;
  htmlAr: string;
  htmlEn: string;
  html: string;
}

export interface TermsAndConditionsObject {
  id: number;
  htmlAr: string;
  htmlEn: string;
  html: string;
}

export interface SiteSettings {
  aboutUs: AboutUsObject;
  termsAndConditions: TermsAndConditionsObject;
  errors: unknown;
}
@Module
export default class SiteSettingsModule
  extends VuexModule
  implements SiteSettings
{
  aboutUs = {} as AboutUsObject;
  termsAndConditions = {} as TermsAndConditionsObject;
  errors = {};

  /**
   * Get About list
   * @returns Array<AboutUsObject>
   */
  get AboutUsView(): AboutUsObject {
    return this.aboutUs;
  }

  get TermsAndConditionsView(): TermsAndConditionsObject {
    return this.termsAndConditions;
  }

  @Mutation
  [Mutations.RESET_TERMS_AND_CONDITIONS]() {
    this.termsAndConditions = {} as TermsAndConditionsObject;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_TERMS_AND_CONDITIONS](data) {
    this.termsAndConditions = data.data;
    this.errors = {};
  }

  @Mutation
  [Mutations.RESET_ABOUT_US]() {
    this.aboutUs = {} as AboutUsObject;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ABOUT_US](data) {
    this.aboutUs = data.data;
    this.errors = {};
  }

  @Action
  [Actions.GET_TERMS_AND_CONDITIONS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/terms-conditions')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TERMS_AND_CONDITIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.UPDATE_TERMS_AND_CONDITIONS]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/terms-conditions', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_TERMS_AND_CONDITIONS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_ABOUT_US]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/about-us')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ABOUT_US, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.UPDATE_ABOUT_US]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/about-us', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ABOUT_US);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
