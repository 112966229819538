import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CceSubCategorieObject {
  id: number;
  titleEn: string;
  titleAr: string;
  categoryId: number;
  categoryName: string;
  multiplier: number;
  defaultPrice: number;
  isDependQuality: boolean;
}

export interface CceSubCategoriesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  cceSubCategories: Array<CceSubCategorieObject>;
  errors: unknown;
}

@Module
export default class SubCategoriesModule
  extends VuexModule
  implements CceSubCategoriesListInfo
{
  cceSubCategories = [] as Array<CceSubCategorieObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get cceSubCategories list
   * @returns Array<CceSubCategorieObject>
   */
  get cceSubCategoriesList(): Array<CceSubCategorieObject> {
    return this.cceSubCategories;
  }

  /**
   * Get total items
   * @returns number
   */
  get cceSubCategoriesListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_CCE_SUB_CATEGORIES](data) {
    this.cceSubCategories = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CCE_SUB_CATEGORIE_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_CCE_SUB_CATEGORIES_LIST]() {
    this.cceSubCategories = [] as Array<CceSubCategorieObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_CCE_SUB_CATEGORIES_STORE]() {
    this.context.commit(Mutations.RESET_CCE_SUB_CATEGORIES_LIST);
  }

  @Action
  [Actions.CREATE_CCE_SUB_CATEGORIE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/cce-sub-categories', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_CCE_SUB_CATEGORIES_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_CCE_SUB_CATEGORIE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/cce-sub-categories', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CCE_SUB_CATEGORIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_CCE_SUB_CATEGORIE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/cce-sub-categories', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_CCE_SUB_CATEGORIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_CCE_SUB_CATEGORIE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_CCE_SUB_CATEGORIE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_CCE_SUB_CATEGORIE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/cce-sub-categories', id);
  }

  @Action
  [Actions.GET_CCE_SUB_CATEGORIES](
    options: { limit?: number } = { limit: 10 }
  ) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: options.limit };
    return ApiService.query('/cce-sub-categories', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CCE_SUB_CATEGORIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
  @Action
  [Actions.UPDATE_CCE_SUB_CATEGORIE_OPTIONS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/cce-sub-categories-options', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_CCE_SUB_CATEGORIES_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
