import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface LocationsConfigObj {
  id: number;
  areaId: number;
  locationId: number;
  pricepermeter: number;
  locationsTitle: string;
  locationTypeId: number;
}

export interface IreInvestmentSizeQualityConfigObj {
  id: number;
  areaId: number;
  sizeId: number;
  qualityId: number;
  isBase: boolean;
  value: number;
  increasePercentage: number;
  priceRentalPerMeter: number;
  calculationPercentage: number;
  isCustom: boolean;
  qualityTitle: string;
  sizeTitle: string;
}

export interface PropertyAgeConfig {
  id: number;
  areaId: number;
  sizeId: number;
  propertyAgeId: number;
  isBase: boolean;
  value: number;
  increasePercentage: number;
  decreasedPercentage: number;
  calculationPercentage: number;
  isCustom: boolean;
  qualityTitle: string;
  sizeTitle: string;
}
export interface IreInvestmentAreaObject {
  id: number;
  areaId: number;
  isActive: boolean;
  sort: number;
  isSeaView: boolean;
  areaName: string;
  baseValue: number;
  editPercentages: boolean;
  firstIncreasePercentage: number;
  secondIncreasePercentage: number;
  firstDecreasedPercentage: number;
  secondDecreasedPercentage: number;
  ireInvestmentSizeQualityConfig: IreInvestmentSizeQualityConfigObj[];
  propertyAgeConfig: PropertyAgeConfig[];
  locationsConfig: LocationsConfigObj[];
  customUpdatedAt: string;
}

export interface IreInvestmentAreaAllListObject {
  id: number;
  titleEn: string;
  areas: any;
  areaName: string;
}
export interface AreaObject {
  id: number;
  title: string;
}

export interface IreInvestmentAreasListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  errors: unknown;
}

@Module
export default class IreInvestmentAreasModule
  extends VuexModule
  implements IreInvestmentAreasListInfo
{
  ireInvestmentAreas = [] as Array<IreInvestmentAreaObject>;
  ireInvestmentAllAreas = [] as Array<IreInvestmentAreaAllListObject>;
  ireInvestmentUnitComp = [] as Array<any>; // ddl for size Desc
  areasFilter = {} as IreInvestmentAreaObject;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get ireInvestmentAreas list
   * @returns Array<IreInvestmentAreasObject>
   */
  get ireInvestmentAreasList(): Array<IreInvestmentAreaObject> {
    if (this.ireInvestmentAreas.length > 0)
      this.ireInvestmentAreas[0]['page'] = this.currentPage;
    return this.ireInvestmentAreas;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get ireInvestmentAreasAllList(): Array<IreInvestmentAreaAllListObject> {
    return this.ireInvestmentAllAreas;
  }

  /**
   * Get all ive areas list
   * @returns Array<AreaObject>
   */
  get ireInvestmentAreaFilter(): IreInvestmentAreaObject {
    return this.areasFilter;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireInvestmentAreasListCount(): number {
    return this.totalItems;
  }

  get ireUnitComp(): Array<any> {
    return this.ireInvestmentUnitComp;
  } // ddl for size desc

  @Mutation
  [Mutations.SET_IRE_INVESTMENT_AREA_FILTER](obj) {
    this.areasFilter.areaId = obj.areaId;
  }

  @Action
  [Actions.UPDATE_IRE_INVESTMENT_AREA_FILTER](val) {
    this.context.commit(Mutations.SET_IRE_INVESTMENT_AREA_FILTER, val);
  }

  @Mutation
  [Mutations.SET_IRE_INVESTMENT_AREAS](data) {
    this.ireInvestmentAreas = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_IRE_INVESTMENT_AREAS](data) {
    this.ireInvestmentAllAreas = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_IRE_UNIT_COMP](data) {
    this.ireInvestmentUnitComp = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_INVESTMENT_AREA_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IRE_INVESTMENT_AREAS_LIST]() {
    this.ireInvestmentAreas = [] as Array<IreInvestmentAreaObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_IRE_INVESTMENT_AREA](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-investment/areas', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_IRE_INVESTMENT_AREAS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IRE_INVESTMENT_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-investment/areas', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_INVESTMENT_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.DELETE_IRE_INVESTMENT_SIZE_CONFIG](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-investment/ire-size-v2-config', id)
      .then(() => {
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_INVESTMENT_AREA]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-investment/areas', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_INVESTMENT_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_INVESTMENT_AREA_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_INVESTMENT_AREA_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IRE_INVESTMENT_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment/areas', id);
  }

  @Action
  [Actions.GET_IRE_INVESTMENT_AREAS](
    options: { limit?: number } = { limit: 10 }
  ) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      limit: options.limit,
      areaId: this.areasFilter.areaId ? this.areasFilter.areaId : '',
    };
    return ApiService.query('/ire-investment/areas', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_INVESTMENT_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_IRE_INVESTMENT_AREAS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment/areas/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IRE_INVESTMENT_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.UPDATE_IRE_SIZE_CONFIG_OPTIONS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-investment/ire-size-v2-config', data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET__LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  // @Action
  // [Actions.UPDATE_IRE_PROPERTY_AGE_CONFIG_OPTIONS](data) {
  //   ApiService.setAuthorizationHeader();
  //   return ApiService.post('/ire-investment/ire-property-v2-age-config', data)
  //     .then(({ data }) => {
  //       // this.context.commit(Mutations.RESET__LIST);
  //       return data.data;
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, {
  //         [response.statusText]: [response.data.message],
  //       });
  //     });
  // }

  @Action
  [Actions.GET_IRE_INVESTMENT_ALL_LOCATIONS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment/locations/all');
  }
  @Action
  [Actions.UPDATE_SHOP_CONFIG](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-investment/ire-shop-basement-values', data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET_APPRAISERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_QUALITY_CONFIG](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-investment/quality-config', data.id, data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET_APPRAISERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_PROPERTY_AGE_CONFIG](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-investment/property-age', data.id, data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET_APPRAISERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_All_IRE_UNIT_COMP]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment/unit-comp')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IRE_UNIT_COMP, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.UPDATE_IRE_UNIT_COMP](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-investment/unit-comp', data).catch(
      ({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      }
    );
  }
}
