import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IreCommonSizeQualityConfigObj {
  id: number;
  streetId: number;
  sizeId: number;
  qualityId: number;
  isBase: boolean;
  value: number;
  increasePercentage: number;
  priceRentalPerMeter: number;
  calculationPercentage: number;
  isCustom: boolean;
  qualityTitle: string;
  sizeTitle: string;
}

export interface IreCommonStreetObject {
  id: number;
  areaId: number;
  isActive: boolean;
  sort: number;
  isSeaView: boolean;
  titleEn: string;
  titleAr: string;
  areaName: string;
  baseValue: number;
  editPercentages: boolean;
  firstIncreasePercentage: number;
  secondIncreasePercentage: number;
  firstDecreasedPercentage: number;
  secondDecreasedPercentage: number;
  ireCommonSizeQualityConfig: IreCommonSizeQualityConfigObj[];
}

export interface IreCommonStreetAllListObject {
  id: number;
  titleEn: string;
  streets: any;
  streetName: string;
}
export interface StreetObject {
  id: number;
  title: string;
}

export interface IreCommonStreetsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  errors: unknown;
}

@Module
export default class IreCommonStreetsModule
  extends VuexModule
  implements IreCommonStreetsListInfo
{
  ireCommonStreets = [] as Array<IreCommonStreetObject>;
  ireCommonAllStreets = [] as Array<IreCommonStreetAllListObject>;
  streetsFilter = {} as IreCommonStreetObject;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get ireCommonStreets list
   * @returns Array<IreCommonStreetsObject>
   */
  get ireCommonStreetsList(): Array<IreCommonStreetObject> {
    if (this.ireCommonStreets.length > 0)
      this.ireCommonStreets[0]['page'] = this.currentPage;
    return this.ireCommonStreets;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get ireCommonStreetsAllList(): Array<IreCommonStreetAllListObject> {
    return this.ireCommonAllStreets;
  }

  /**
   * Get all ive streets list
   * @returns Array<StreetObject>
   */
  get ireCommonStreetFilter(): IreCommonStreetObject {
    return this.streetsFilter;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireCommonStreetsListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_STREET_FILTER](obj) {
    this.streetsFilter.areaId = obj.areaId;
  }

  @Action
  [Actions.UPDATE_IRE_COMMON_STREET_FILTER](val) {
    this.context.commit(Mutations.SET_IRE_COMMON_STREET_FILTER, val);
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_STREETS](data) {
    this.ireCommonStreets = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_IRE_COMMON_STREETS](data) {
    this.ireCommonAllStreets = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_STREET_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IRE_COMMON_STREETS_LIST]() {
    this.ireCommonStreets = [] as Array<IreCommonStreetObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_IRE_COMMON_STREET](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-common/street', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_IRE_COMMON_STREETS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IRE_COMMON_STREET](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-common/street', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_COMMON_STREETS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_COMMON_STREET]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-common/street', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_COMMON_STREETS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPDATE_IRE_COMMON_STREET_CONFIG]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-common/street-config', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_COMMON_STREETS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_COMMON_STREET_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_COMMON_STREET_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IRE_COMMON_STREET](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-common/street', id);
  }

  @Action
  [Actions.GET_IRE_COMMON_STREETS](
    options: { limit?: number; type?: number } = { limit: 10, type: 3 }
  ) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      limit: options.limit,
      type: options.type,
      areaId: this.streetsFilter.areaId ? this.streetsFilter.areaId : '',
    };
    return ApiService.query('/ire-common/street', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_STREETS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_IRE_COMMON_STREETS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-common/street/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IRE_COMMON_STREETS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.UPDATE_IRE_SIZE_CONFIG_OPTIONS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-common/ire-size-v2-config', data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET__LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
