import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface FactorOptionFlags {
  isMultiCurb: boolean;
  isMultiSelection: boolean;
}

export interface FactorOptionObject {
  id: number;
  titleEn: string;
  titleAr: string;
  slug: string;
  isActive: boolean;
  multiplicationFactors: number;
  flags: null | FactorOptionFlags;
  title: string;
}

export interface FactorOptionsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  factorOptions: Array<FactorOptionObject>;
  errors: unknown;
}

@Module
export default class FactorOptionsModule
  extends VuexModule
  implements FactorOptionsListInfo
{
  factorOptions = [] as Array<FactorOptionObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get factorOptions list
   * @returns Array<FactorOptionObject>
   */
  get factorOptionsList(): Array<FactorOptionObject> {
    return this.factorOptions;
  }

  /**
   * Get total items
   * @returns number
   */
  get factorOptionsListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_IVE_FACTOR_OPTIONS](data) {
    this.factorOptions = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IVE_FACTOR_OPTIONS_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IVE_FACTOR_OPTIONS_LIST]() {
    this.factorOptions = [] as Array<FactorOptionObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_IVE_FACTOR_OPTIONS_STORE]() {
    this.context.commit(Mutations.RESET_IVE_FACTOR_OPTIONS_LIST);
  }

  @Action
  [Actions.CREATE_IVE_FACTOR_OPTION]({ data, factorId }) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ive-factor-options', { ...data, factorId })
      .then(() => {
        this.context.commit(Mutations.RESET_IVE_FACTOR_OPTIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IVE_FACTOR_OPTION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ive-factor-options', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IVE_FACTOR_OPTIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IVE_FACTOR_OPTION]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-factor-options', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IVE_FACTOR_OPTIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IVE_FACTOR_OPTION_DEFAULT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/instant-value-estimator/set-default', id, data)
      .then(() => {
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IVE_FACTOR_OPTION_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IVE_FACTOR_OPTIONS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IVE_FACTOR_OPTION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-factor-options', id);
  }

  @Action
  [Actions.GET_IVE_FACTOR_OPTIONS](factorId) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: 10, factorId };
    return ApiService.query('/ive-factor-options', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IVE_FACTOR_OPTIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
