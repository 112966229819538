import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface LocationObject {
  id: number;
  organiserId: number;
  venueEn: string;
  venueAr: string;
  isActive: boolean;
  latitude: string;
  longitude: string;
}

export interface LocationsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  locations: Array<LocationObject>;
  errors: unknown;
}

@Module
export default class LocationsModule
  extends VuexModule
  implements LocationsListInfo
{
  locations = [] as Array<LocationObject>;
  organizerLocations = [] as Array<LocationObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get locations list
   * @returns Array<LocationObject>
   */
  get locationsList(): Array<LocationObject> {
    return this.locations;
  }

  /**
   * Get total items
   * @returns number
   */
  get locationsListCount(): number {
    return this.totalItems;
  }

  /**
   * Get organizer location
   * @returns Array<LocationObject>
   */
  get organizerLocationsList(): Array<LocationObject> {
    return this.organizerLocations;
  }

  @Mutation
  [Mutations.SET_ORGANIZER_LOCATIONS](data) {
    this.organizerLocations = data.data;
  }

  @Mutation
  [Mutations.SET_LOCATIONS](data) {
    this.locations = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_LOCATION_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_LOCATIONS_LIST]() {
    this.locations = [] as Array<LocationObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_LOCATIONS_STORE]() {
    this.context.commit(Mutations.RESET_LOCATIONS_LIST);
  }

  @Action
  [Actions.CREATE_LOCATION](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/organisers-locations', data)
      .then(() => {
        this.context.commit(Mutations.RESET_LOCATIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_LOCATION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/organisers-locations', id)
      .then(() => {
        this.context.commit(Mutations.RESET_LOCATIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_LOCATION]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/organisers-locations', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_LOCATIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_LOCATION_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_LOCATION_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_LOCATION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/organisers-locations', id);
  }

  @Action
  [Actions.GET_LOCATIONS]() {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: 10 };
    return ApiService.query('/organisers-locations', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.coordinates = `[${r.latitude}, ${r.longitude}]`;
          return r;
        });
        this.context.commit(Mutations.SET_LOCATIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ORGANIZER_LOCATIONS](organizerId) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(
      `/organisers-locations/organisers-locations/all/${organizerId}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORGANIZER_LOCATIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
