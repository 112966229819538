import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface RoleObject {
  id: number;
  name: string;
  description: string;
  groupId: number;
  rolePermission: Array<number>;
  createdBy: string;
  createdAt: Date;
}

export interface GroupObject {
  id: number;
  name: string;
  description: string;
  rolePermission: Array<number>;
  createdAt: Date;
}

export interface RolesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  roles: Array<RoleObject>;
  errors: unknown;
}

@Module
export default class RolesModule extends VuexModule implements RolesListInfo {
  roles = [] as Array<RoleObject>;
  groups = [] as Array<GroupObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get roles list
   * @returns Array<RoleObject>
   */
  get rolesList(): Array<RoleObject> {
    return this.roles;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get rolesGroupsList(): Array<GroupObject> {
    return this.groups;
  }

  /**
   * Get total items
   * @returns number
   */
  get rolesListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_ROLES](data) {
    this.roles = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ROLES_GROUPS](data) {
    this.groups = data.data;
  }

  @Mutation
  [Mutations.SET_ROLE_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_ROLES_LIST]() {
    this.roles = [] as Array<RoleObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_ROLES_STORE]() {
    this.context.commit(Mutations.RESET_ROLES_LIST);
  }

  @Action
  [Actions.CREATE_ROLE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/roles', data)
      .then(() => {
        this.context.commit(Mutations.RESET_ROLES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_ROLE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('roles', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ROLES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_ROLE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('roles', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ROLES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_ROLE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_ROLE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ROLE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('roles', id);
  }

  @Action
  [Actions.GET_ROLES](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: options.limit };
    return ApiService.query('/roles', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_ROLES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ROLES_GROUPS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/roles/groups')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLES_GROUPS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
