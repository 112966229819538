import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface User {
  id: number;
  name: string;
  email: string;
  memberTypeTitle: string;
  createdAt: Date;
  phone: string;
  is_active: boolean;
}

export interface UsersListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  users: Array<User>;
  errors: unknown;
}

@Module
export default class UsersModule extends VuexModule implements UsersListInfo {
  users = [] as Array<User>;
  appUsers = [] as Array<User>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get users list
   * @returns Array<User>
   */
  get usersList(): Array<User> {
    return this.users;
  }

  /**
   * Get users list
   * @returns Array<User>
   */
  get usersAllList(): Array<User> {
    return this.appUsers;
  }

  /**
   * Get total items
   * @returns number
   */
  get listUsersCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_USERS](data) {
    this.users = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_USER_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.SET_ALL_APP_USERS](data) {
    this.appUsers = data.data;
  }

  @Mutation
  [Mutations.RESET_USERS_LIST]() {
    this.users = [] as Array<User>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_USERS_STORE]() {
    this.context.commit(Mutations.RESET_USERS_LIST);
  }

  @Action
  [Actions.UPDATE_USER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('members', id, {})
      .then(() => {
        this.context.commit(Mutations.RESET_USERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_USER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_USER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_USER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('members', id);
  }

  @Action
  [Actions.GET_USERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/members', { params: { page: this.currentPage } })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_USERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_APP_USERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/members/all-users')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_APP_USERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
}
