import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IreFactorObject {
  id: number;
  titleEn: string;
  titleAr: string;
  type: string;
  sort: number;
  factorId: number;
  configValue?: number;
  configId?: number;
}
export interface ireFactorsConfigObject {
  ireFactorOption: string;
  value: string;
  fOptionId: number;
}

export interface IreFactorValuesObject {
  typeId: number;
  ireFactorsConfig: Array<ireFactorsConfigObject>;
}

export interface FactorOptionObject {
  ireFactorOption: string;
  value: string;
  fOptionId: number;
  id: number;
  title: string;
}

export interface IreFactorsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  ireFactors: Array<IreFactorObject>;
  errors: unknown;
}

@Module
export default class IreFactorssModule
  extends VuexModule
  implements IreFactorsListInfo
{
  ireFactors = [] as Array<IreFactorObject>;
  ireFactorsAreasValues = [] as Array<IreFactorValuesObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};
  factorId = 1;

  /**
   * Get ireFactors list
   * @returns Array<IreFactorsObject>
   */
  get ireFactorsList(): Array<IreFactorObject> {
    return this.ireFactors;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireFactorsListCount(): number {
    return this.totalItems;
  }
  get ireFactorsAreasValuesList(): Array<IreFactorValuesObject> {
    return this.ireFactorsAreasValues;
  }

  @Mutation
  [Mutations.SET_IRE_FACTORS](data) {
    this.ireFactors = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_FACTOR_AREAS_VALUES](data) {
    this.ireFactorsAreasValues = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_FACTOR_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IRE_FACTORS_LIST]() {
    this.ireFactorsAreasValues = [] as Array<IreFactorValuesObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_IRE_FACTOR](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-factors/factors-options', data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_FACTORS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IRE_FACTOR](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-factors/factors-options', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_FACTORS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IRE_FACTOR_VALUES](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire/ire-factors-areas-values', id)
      .then(() => {
        // this.context.commit(Mutations.RESET_IRE_FACTORS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_FACTOR]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-factors/factors-options', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_FACTORS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPDATE_IRE_FACTOR_VALUES](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire/ire-factors-areas-values', data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_FACTORS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_FACTOR_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_FACTOR_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IRE_FACTOR](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-factors/factors-options', id);
  }

  @Action
  [Actions.GET_IRE_FACTORS](
    factorId,
    options: { limit?: number } = { limit: 10 }
  ) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      limit: options.limit,
      factorId: factorId,
    };
    return ApiService.query('/ire-factors/factors-options', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_FACTORS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_IRE_FACTOR_AREAS_VALUES]({ areaId, factorId }) {
    ApiService.setAuthorizationHeader();
    const params = { areaId: Number(areaId), factorId: Number(factorId) };
    return ApiService.query('/ire/ire-factors-areas-values', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_FACTOR_AREAS_VALUES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
