import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface BankRatesObject {
  id: number;
  repaymentPeriod: number;
  bankId: number;
  percentage: number;
  notClientPercentage: number;
  isActive: boolean;
  isMin: boolean;
}

export interface bankRatesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  bankRates: Array<BankRatesObject>;
  errors: unknown;
}
export interface bankRatesLogReports {
  id: number;
  user: string;
  action: string;
  value: string;
  status: string;
  createdAt: string;
}

export interface BankRatesLogReportsFilterInfo {
  dateFrom: string | null;
  dateTo: string | null;
  action: string | null;
  status: string | null;
}

@Module
export default class BankRatesModule
  extends VuexModule
  implements bankRatesListInfo
{
  bankRates = [] as Array<BankRatesObject>;
  logReports = [] as Array<bankRatesLogReports>;
  exportedLogReports = [] as Array<bankRatesLogReports>;
  bankRatesLogReportsFilter = {} as BankRatesLogReportsFilterInfo;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get bankRates list
   * @returns Array<BankRatesObject>
   */
  get bankRatesList(): Array<BankRatesObject> {
    return this.bankRates;
  }

  /**
   * Get bankRates list
   * @returns Array<bankRatesLogReports>
   */
  get bankRatesLogReportsList(): Array<bankRatesLogReports> {
    return this.logReports;
  }

  /**
   * Get total items
   * @returns number
   */
  get bankRatesListCount(): number {
    return this.totalItems;
  }
  get exportedLogReportsList(): Array<bankRatesLogReports> {
    return this.exportedLogReports;
  }

  @Mutation
  [Mutations.SET_BANK_RATES](data) {
    this.bankRates = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
  }
  @Mutation
  [Mutations.SET_BANK_RATE_LOG_REPORTS](data) {
    this.logReports = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
  }

  @Mutation
  [Mutations.SET_BANK_RATE_CURRENT_PAGE](page) {
    this.currentPage = page;
  }
  @Mutation
  [Mutations.SET_EXPORT_RATES_LOGS](data) {
    this.exportedLogReports = data.data;
  }

  @Mutation
  [Mutations.RESET_BANK_RATES_LIST]() {
    this.bankRates = [] as Array<BankRatesObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_BANK_RATES_FILTER](obj) {
    this.bankRatesLogReportsFilter.dateFrom = String(obj.dateFrom);
    this.bankRatesLogReportsFilter.dateTo = String(obj.dateTo);
    this.bankRatesLogReportsFilter.action = String(obj.action);
    this.bankRatesLogReportsFilter.status = String(obj.status);
  }

  @Action
  [Actions.UPDATE_BANK_RATES_FILTER](val) {
    this.context.commit(Mutations.SET_BANK_RATES_FILTER, val);
  }

  @Action
  [Actions.RESET_BANK_RATES_STORE]() {
    this.context.commit(Mutations.RESET_BANK_RATES_LIST);
  }

  @Action
  [Actions.CREATE_BANK_RATE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/banks-rates', data)
      .then(() => {
        this.context.commit(Mutations.RESET_BANK_RATES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_BANK_RATE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/banks-rates', id)
      .then(() => {
        this.context.commit(Mutations.RESET_BANK_RATES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_BANK_RATE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/banks-rates', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_BANK_RATES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_BANK_RATE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_BANK_RATE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_BANK_RATE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/banks-rates', id);
  }

  @Action
  [Actions.GET_BANK_RATES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/banks-rates', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BANK_RATES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_BANK_RATES_ENUM_ALL]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/banks-rates/rates-enums/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BANK_RATES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_BANK_RATES_LOG_REPORTS]() {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      dateFrom: this.bankRatesLogReportsFilter.dateFrom
        ? this.bankRatesLogReportsFilter.dateFrom
        : '',
      dateTo: this.bankRatesLogReportsFilter.dateTo
        ? this.bankRatesLogReportsFilter.dateTo
        : '',
      action: this.bankRatesLogReportsFilter.action
        ? this.bankRatesLogReportsFilter.action
        : '',
      status: this.bankRatesLogReportsFilter.status
        ? this.bankRatesLogReportsFilter.status
        : '',
    };
    return ApiService.query('/banks-rates/logs', {
      params,
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BANK_RATE_LOG_REPORTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.IMPORT_BANK_RATE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/banks-rates/rates/import', data)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.EXPORT_RATES_LOGS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/banks-rates/logs/export')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXPORT_RATES_LOGS, data);
      })
      .catch(({ response }) => {
        console.error(response);

        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_BANK_RATE_SETTINGS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/banks-rates/settings');
  }
  @Action
  [Actions.UPDATE_BANK_RATE_SETTINGS]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/banks-rates/settings', id, data)
      .then(() => {
        // this.context.commit(Mutations.RESET_BANK_RATES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
