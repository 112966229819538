import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IreSizeObject {
  id: number;
  titleEn: string;
  titleAr: string;
  type: string;
  sort: number;
}

export interface IreSizeValuesObject {
  id: number;
  titleEn: string;
  titleAr: string;
  slug: string;
  sort: number;
  typeId: number;
  isActive: boolean;
  title: string;
  type: string;
}

export interface SizeObject {
  id: number;
  title: string;
}

export interface IreSizesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  ireSizes: Array<IreSizeObject>;
  errors: unknown;
}

@Module
export default class IreSizessModule
  extends VuexModule
  implements IreSizesListInfo
{
  ireSizes = [] as Array<IreSizeObject>;
  sizes = [] as Array<SizeObject>;
  ireSizesAreasValues = [] as Array<IreSizeValuesObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get ireSizes list
   * @returns Array<IreSizesObject>
   */
  get ireSizesList(): Array<IreSizeObject> {
    return this.ireSizes;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get sizesList(): Array<SizeObject> {
    return this.sizes;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireSizesListCount(): number {
    return this.totalItems;
  }
  get ireSizesAreasValuesList(): Array<IreSizeValuesObject> {
    return this.ireSizesAreasValues;
  }

  @Mutation
  [Mutations.SET_IRE_SIZES](data) {
    this.ireSizes = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SIZES](data) {
    this.sizes = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_SIZE_AREAS_VALUES](data) {
    this.ireSizesAreasValues = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_SIZE_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IRE_SIZES_LIST]() {
    this.ireSizes = [] as Array<IreSizeObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_IRE_SIZE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire/ire-sizes', data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_SIZES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IRE_SIZE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire/ire-size', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_SIZES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_SIZE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire/ire-sizes', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_SIZES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_SIZE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_SIZE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IRE_SIZE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire/ire-sizes', id);
  }

  @Action
  [Actions.GET_IRE_SIZES](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: options.limit };
    return ApiService.query('/ire/ire-sizes', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_SIZES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_IRE_SIZE_AREAS_VALUES](areaId) {
    ApiService.setAuthorizationHeader();
    const params = { areaId: Number(areaId) };
    return ApiService.query('/ire/ire-size-areas-values', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_SIZE_AREAS_VALUES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.UPDATE_IRE_SIZE_FACTOR_VALUES](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('ire/ire-size-areas-values', data).catch(
      ({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      }
    );
  }
}
