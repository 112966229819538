import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CceCategorieObject {
  id: number;
  titleEn: string;
  titleAr: string;
  groupId: number;
  groupName: string;
}

export interface CceAllCategoriesObject {
  id: number;
  title: string;
}

export interface CceCategoriesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  cceCategories: Array<CceCategorieObject>;
  errors: unknown;
}

@Module
export default class CategoriesModule
  extends VuexModule
  implements CceCategoriesListInfo
{
  cceCategories = [] as Array<CceCategorieObject>;
  cceAllCategories = [] as Array<CceAllCategoriesObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get cceCategories list
   * @returns Array<CceCategorieObject>
   */
  get cceCategoriesList(): Array<CceCategorieObject> {
    return this.cceCategories;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get allCceCategoriesList(): Array<CceAllCategoriesObject> {
    return this.cceAllCategories;
  }

  /**
   * Get total items
   * @returns number
   */
  get cceCategoriesListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_CCE_CATEGORIES](data) {
    this.cceCategories = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_CCE_CATEGORIES](data) {
    this.cceAllCategories = data.data;
  }

  @Mutation
  [Mutations.SET_CCE_CATEGORIE_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_CCE_CATEGORIES_LIST]() {
    this.cceCategories = [] as Array<CceCategorieObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_CCE_CATEGORIES_STORE]() {
    this.context.commit(Mutations.RESET_CCE_CATEGORIES_LIST);
  }

  @Action
  [Actions.CREATE_CCE_CATEGORIE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/cce-categories', data)
      .then(() => {
        this.context.commit(Mutations.RESET_CCE_CATEGORIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_CCE_CATEGORIE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/cce-categories', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CCE_CATEGORIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_CCE_CATEGORIE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/cce-categories', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_CCE_CATEGORIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_CCE_CATEGORIE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_CCE_CATEGORIE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_CCE_CATEGORIE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/cce-categories', id);
  }

  @Action
  [Actions.GET_CCE_CATEGORIES](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: options.limit };
    return ApiService.query('/cce-categories', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CCE_CATEGORIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_ALL_CCE_CATEGORIES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/cce-categories/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_CCE_CATEGORIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
