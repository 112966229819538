import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface DisclaimerObject {
  id: number;
  screen: string;
  descEn: string;
  descAr: string;
}
export interface DisclaimerListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  disclaimer: Array<DisclaimerObject>;
  errors: unknown;
}
@Module
export default class DisclaimerModule
  extends VuexModule
  implements DisclaimerListInfo
{
  disclaimer = [] as Array<DisclaimerObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get Disclaimer list
   * @returns Array<BankObject>
   */
  get disclaimerList(): Array<DisclaimerObject> {
    return this.disclaimer;
  }

  /**
   * Get total items
   * @returns number
   */
  get disclaimerListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_DISCLAIMERS](data) {
    this.disclaimer = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }
  @Mutation
  [Mutations.RESET_DISCLAIMER_LIST]() {
    this.disclaimer = [] as Array<DisclaimerObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_DISCLAIMER_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Action
  [Actions.UPDATE_DISCLAIMER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_DISCLAIMER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.UPDATE_DISCLAIMER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/disclaimer', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_DISCLAIMER_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_DISCLAIMER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/disclaimer', id);
  }

  @Action
  [Actions.GET_DISCLAIMERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/disclaimer', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISCLAIMERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
