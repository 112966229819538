import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface QuestionObject {
  id: number;
  titleEn: string;
  titleAr: string;
  slug: string;
  tempWeight: number;
  weight: number;
  isActive: boolean;
  title: string;
  sort: number;
  groupId: number;
}

export interface questionsListInfo {
  questions: Array<QuestionObject>;
  errors: unknown;
}

@Module
export default class QuestionsModule
  extends VuexModule
  implements questionsListInfo
{
  questions = [] as Array<QuestionObject>;
  errors = {};

  /**
   * Get Quality Groups list
   * @returns Array<QuestionObject>
   */
  get questionsList(): Array<QuestionObject> {
    return this.questions;
  }

  @Mutation
  [Mutations.SET_QUESTIONS](data) {
    this.questions = data.data;
    this.errors = {};
  }

  @Mutation
  [Mutations.RESET_QUESTIONS]() {
    this.questions = [] as Array<QuestionObject>;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_QUESTION](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ive-fq-questions', data)
      .then(() => {
        this.context.commit(Mutations.RESET_QUESTIONS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_QUESTION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ive-fq-questions', id)
      .then(() => {
        this.context.commit(Mutations.RESET_QUESTIONS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_QUESTION]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-fq-questions', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_QUESTIONS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_QUESTION_WEIGHT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update(
      '/ive-fq-questions/update-questions-weight',
      id,
      data
    )
      .then(() => {
        this.context.commit(Mutations.RESET_QUESTIONS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_QUESTION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-fq-questions', id);
  }
}
