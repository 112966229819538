import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface LoanFundsRequestObject {
  id: number;
  totalPurchasePrice: number;
  bankProfit: number;
  loanDuration: string;
  propertyValue: number;
  downPayment: number;
  loanAmount: number;
  annualProfit: number;
  gracePeriod: string;
  repaymentPeriod: string;
  name: string;
  email: string;
  phone: string;
  message: string;
  createdAt: Date;
  status: boolean;
}
export interface LoanFundRequestListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  loans: Array<LoanFundsRequestObject>;
  errors: unknown;
}
@Module
export default class LoanFundsRequestModule
  extends VuexModule
  implements LoanFundRequestListInfo
{
  loans = [] as Array<LoanFundsRequestObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get Loans list
   * @returns Array<LoansObject>
   */
  get loansList(): Array<LoanFundsRequestObject> {
    return this.loans;
  }

  /**
   * Get total items
   * @returns number
   */
  get loansListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_LOANS_FUNDS_REQUEST](data) {
    this.loans = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.RESET_LOANS_FUNDS_REQUEST_LIST]() {
    this.loans = [] as Array<LoanFundsRequestObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_LOANS_FUNDS_REQUEST_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Action
  [Actions.RESET_LOANS_FUNDS_REQUEST_STORE]() {
    this.context.commit(Mutations.RESET_LOANS_FUNDS_REQUEST_LIST);
  }

  @Action
  [Actions.UPDATE_LOANS_FUNDS_REQUEST_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_LOANS_FUNDS_REQUEST_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_LOAN_FUNDS_REQUEST](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/loan-calculator', id);
  }

  @Action
  [Actions.GET_LOANS_FUNDS_REQUESTS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/loan-calculator', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_LOANS_FUNDS_REQUEST, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR,{
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
