import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IreV2FactorObject {
  id: number;
  titleEn: string;
  titleAr: string;
  type: string;
  sort: number;
  factorId: number;
}
export interface ireV2FactorsConfigObject {
  ireV2FactorOption: string;
  value: string;
  fOptionId: number;
}

export interface IreV2FactorValuesObject {
  typeId: number;
  ireV2FactorsConfig: Array<ireV2FactorsConfigObject>;
}

export interface FactorOptionObject {
  ireV2FactorOption: string;
  value: string;
  fOptionId: number;
  id: number;
  title: string;
}

export interface IreV2FactorsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  ireV2Factors: Array<IreV2FactorObject>;
  errors: unknown;
}

@Module
export default class IreV2FactorssModule
  extends VuexModule
  implements IreV2FactorsListInfo
{
  ireV2Sizes = [] as Array<any>; // ddl for size
  ireV2PropertysAge = [] as Array<any>; // ddl for property age
  ireV2SizesDesc = [] as Array<any>; // ddl for size Desc
  ireV2Factors = [] as Array<IreV2FactorObject>;
  ireV2FactorsAreasValues = [] as Array<IreV2FactorValuesObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};
  factorType = '';

  /**
   * Get ireV2Factors list
   * @returns Array<IreV2FactorsObject>
   */
  get ireV2FactorsList(): Array<IreV2FactorObject> {
    return this.ireV2Factors;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireV2FactorsListCount(): number {
    return this.totalItems;
  }
  get ireV2FactorsAreasValuesList(): Array<IreV2FactorValuesObject> {
    return this.ireV2FactorsAreasValues;
  }
  get ireV2Size(): Array<any> {
    return this.ireV2Sizes;
  } // ddl for size

  get ireV2PropertyAge(): Array<any> {
    return this.ireV2PropertysAge;
  } // ddl for property age

  get ireV2SizeDesc(): Array<any> {
    return this.ireV2SizesDesc;
  } // ddl for size desc

  @Mutation
  [Mutations.SET_IRE_V2_FACTORS](data) {
    this.ireV2Factors = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_V2_FACTOR_AREAS_VALUES](data) {
    this.ireV2FactorsAreasValues = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_IRE_V2_SIZE](data) {
    this.ireV2Sizes = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_IRE_V2_PROPERTY_AGE](data) {
    this.ireV2PropertysAge = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_IRE_V2_SIZE_DESC](data) {
    this.ireV2SizesDesc = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_V2_FACTOR_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IRE_V2_FACTORS_LIST]() {
    this.ireV2FactorsAreasValues = [] as Array<IreV2FactorValuesObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  // @Action
  // [Actions.CREATE_IRE_V2_FACTOR](data) {
  //   ApiService.setAuthorizationHeader();
  //   return ApiService.post('/ire-factors/factors-options', data)
  //     .then(() => {
  //       this.context.commit(Mutations.RESET_IRE_V2_FACTORS_LIST);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, {
  //         [response.statusText]: [response.data.message],
  //       });
  //     });
  // }

  // @Action
  // [Actions.DELETE_IRE_V2_FACTOR](id) {
  //   ApiService.setAuthorizationHeader();
  //   return ApiService.delete('/ire-factors/factors-options', id)
  //     .then(() => {
  //       this.context.commit(Mutations.RESET_IRE_V2_FACTORS_LIST);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, {
  //         [response.statusText]: [response.data.message],
  //       });
  //     });
  // }

  // @Action
  // [Actions.DELETE_IRE_V2_FACTOR_VALUES](id) {
  //   ApiService.setAuthorizationHeader();
  //   return ApiService.delete('/ire/ire-factors-areas-values', id)
  //     .then(() => {
  //       // this.context.commit(Mutations.RESET_IRE_V2_FACTORS_LIST);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, {
  //         [response.statusText]: [response.data.message],
  //       });
  //     });
  // }

  @Action
  [Actions.UPDATE_IRE_V2_FACTOR]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-v2-factors/property-type', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_V2_FACTORS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_V2_Floor_FACTOR]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-v2-factors/floor-type', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_V2_FACTORS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_V2_Age_FACTOR]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-v2-factors/age-type', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_V2_FACTORS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  // @Action
  // [Actions.UPDATE_IRE_V2_FACTOR_VALUES](data) {
  //   ApiService.setAuthorizationHeader();
  //   return ApiService.post('/ire/ire-factors-areas-values', data)
  //     .then(() => {
  //       this.context.commit(Mutations.RESET_IRE_V2_FACTORS_LIST);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, {
  //         [response.statusText]: [response.data.message],
  //       });
  //     });
  // }

  @Action
  [Actions.UPDATE_IRE_V2_FACTOR_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_V2_FACTOR_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IRE_V2_FACTOR](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-v2-factors/property-type', id);
  }

  @Action
  [Actions.GET_IRE_V2_Floor_FACTOR](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-v2-factors/floor-type', id);
  }

  @Action
  [Actions.GET_IRE_V2_Age_FACTOR](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-v2-factors/age-type', id);
  }

  @Action
  [Actions.GET_IRE_V2_FACTORS](
    factorType,
    options: { limit?: number } = { limit: 10 }
  ) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      limit: options.limit,
      factorType: factorType,
    };
    return ApiService.query('/ive-v2-factors', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_V2_FACTORS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_All_IRE_V2_SIZE]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-v2-factors/ire-size-v2/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IRE_V2_SIZE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_All_IRE_V2_PROPERTY_AGE]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-v2-factors/ire-property-age-v2/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IRE_V2_PROPERTY_AGE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_All_IRE_V2_SIZE_DESC](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-v2-factors/ire-size-desc-v2/all', id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IRE_V2_SIZE_DESC, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.UPDATE_IRE_SIZE_DESC_VALUES](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(
      '/ive-v2-factors/ire-factors-areas-values',
      data
    ).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR, {
        [response.statusText]: [response.data.message],
      });
    });
  }

  // @Action
  // [Actions.GET_IRE_V2_FACTOR_AREAS_VALUES]({ areaId, factorId }) {
  //   ApiService.setAuthorizationHeader();
  //   const params = { areaId: Number(areaId), factorId: Number(factorId) };
  //   return ApiService.query('/ire/ire-factors-areas-values', { params })
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.SET_IRE_V2_FACTOR_AREAS_VALUES, data);
  //     })
  //     .catch(({ response }) => {
  //       console.error(response);
  //       // this.context.commit(Mutations.SET_ERROR, {
  //       //   [response.statusText]: [response.data.message],
  //       // });
  //     });
  // }
}
