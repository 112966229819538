import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface PermissionObject {
  id: number;
  resource: string;
  groupId: number;
  description: string;
  method: number;
}

export interface Permissions {
  [key: string]: Array<PermissionObject>;
}

export interface PermissionsListInfo {
  permissions: Permissions;
  errors: unknown;
}

@Module
export default class PermissionsModule
  extends VuexModule
  implements PermissionsListInfo
{
  permissions = {} as Permissions;
  errors = {};

  /**
   * Get permissions list
   * @returns Permissions
   */
  get permissionsList(): Permissions {
    return this.permissions;
  }

  @Mutation
  [Mutations.SET_PERMISSIONS](data) {
    this.permissions = data.data;
    this.errors = {};
  }

  @Mutation
  [Mutations.RESET_PERMISSIONS_LIST]() {
    this.permissions = {} as Permissions;
    this.errors = {};
  }

  @Action
  [Actions.GET_PERMISSIONS](groupId) {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/permission', { params: { groupId } })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PERMISSIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
