import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AreaObject {
  id: number;
  areaId: number;
  price: number;
  titleEn: string;
  titleAr: string;
  updatedAt: string;
  createdAt: string;
  isActive: boolean;
  areaName: string;
  basePrice: number;
  baseSize: number;
  isSeaView: boolean;
  customUpdatedAt: string;
}

export interface AreasListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  areas: Array<AreaObject>;
  errors: unknown;
}

@Module
export default class IveAreasModule
  extends VuexModule
  implements AreasListInfo
{
  areas = [] as Array<AreaObject>;
  allAreas = [] as Array<AreaObject>;
  exportedIveAraas = [] as Array<AreaObject>;
  areasFilter = {} as AreaObject;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get ive areas list
   * @returns Array<AreaObject>
   */
  get iveAreasList(): Array<AreaObject> {
    if (this.areas.length > 0) this.areas[0]['page'] = this.currentPage;
    return this.areas;
  }

  /**
   * Get all ive areas list
   * @returns Array<AreaObject>
   */
  get allIveAreasList(): Array<AreaObject> {
    return this.allAreas;
  }

  /**
   * Get all ive areas list
   * @returns Array<AreaObject>
   */
  get iveAreaFilter(): AreaObject {
    return this.areasFilter;
  }

  /**
   * Get total items
   * @returns number
   */
  get iveAreasListCount(): number {
    return this.totalItems;
  }
  get exportedIveAreasList(): Array<AreaObject> {
    return this.exportedIveAraas;
  }

  @Mutation
  [Mutations.SET_IVE_AREAS](data) {
    this.areas = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_IVE_AREAS](data) {
    this.allAreas = data.data;
  }

  @Mutation
  [Mutations.SET_IVE_AREA_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.SET_EXPORT_IVE_AREAS](data) {
    this.exportedIveAraas = data.data;
  }

  @Mutation
  [Mutations.RESET_IVE_AREAS_LIST]() {
    this.areas = [] as Array<AreaObject>;
    this.areasFilter = {} as AreaObject;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_IVE_AREA_FILTER](obj) {
    this.areasFilter.areaId = obj.areaId;
    this.areasFilter.price = obj.price;
  }

  @Action
  [Actions.UPDATE_IVE_AREA_FILTER](val) {
    this.context.commit(Mutations.SET_IVE_AREA_FILTER, val);
  }

  @Action
  [Actions.RESET_IVE_AREAS_STORE]() {
    this.context.commit(Mutations.RESET_IVE_AREAS_LIST);
  }

  @Action
  [Actions.CREATE_IVE_AREA](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ive-areas-price-per-meter', data)
      .then(() => {
        this.context.commit(Mutations.RESET_IVE_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.CREATE_IVE_CLONE_AREA](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ive-areas-price-per-meter', data);
    // .then(() => {
    //   this.context.commit(Mutations.RESET_IVE_AREAS_LIST);
    // })
    // .catch(({ response }) => {
    //   this.context.commit(Mutations.SET_ERROR, {
    //     [response.statusText]: [response.data.message],
    //   });
    // });
  }

  @Action
  [Actions.DELETE_IVE_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ive-areas-price-per-meter', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IVE_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IVE_AREA]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-areas-price-per-meter', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IVE_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IVE_AREA_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IVE_AREA_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IVE_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-areas-price-per-meter', id);
  }

  @Action
  [Actions.GET_IVE_AREAS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/ive-areas-price-per-meter', {
      params: {
        page: this.currentPage,
        areaId: this.areasFilter.areaId ? this.areasFilter.areaId : '',
        price: this.areasFilter.price ? this.areasFilter.price : '',
      },
    })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.updatedAt = new Date(r.updatedAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_IVE_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_IVE_AREAS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-areas-price-per-meter/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IVE_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.EXPORT_IVE_AREA]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-areas-price-per-meter/export')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXPORT_IVE_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
