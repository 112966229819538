import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

@Module
export default class ReportsModule extends VuexModule {
  registeredTrades = [] as Array<any>;
  registeredTradesTop = [] as Array<any>;
  registeredTradesPerQuarter = [] as Array<any>;
  registeredTradesTopAreas = [] as Array<any>;
  registeredTradesDesc = [] as Array<any>;
  auctions = [] as Array<any>;
  estimator = [] as Array<any>;
  users = [] as Array<any>;
  ads = [] as Array<any>;

  /**
   * Get registeredTradesDesc list
   * @returns Array<registeredTradesDesc>
   */
  get getregisteredTradesDesc(): Array<any> {
    return this.registeredTradesDesc;
  }

  /**
   * Get registeredTrades list
   * @returns Array<RegisteredTradeObject>
   */
  get registeredTradesReports(): Array<any> {
    return this.registeredTrades;
  }

  /**
   * Get registeredTrades list
   * @returns Array<RegisteredTradeObject>
   */
  get registeredTradesReportsPerQuarter(): Array<any> {
    return this.registeredTradesPerQuarter;
  }

  /**
   * Get registeredTrades list
   * @returns Array<RegisteredTradeObject>
   */
  get registeredTradesReportsTopAreas(): Array<any> {
    return this.registeredTradesTopAreas;
  }

  /**
   * Get registeredTrades list
   * @returns Array<RegisteredTradeObject>
   */
  get registeredTradesReportsTop(): Array<any> {
    return this.registeredTradesTop;
  }

  /**
   * Get auctions list
   * @returns Array<RegisteredTradeObject>
   */
  get auctionsReports(): Array<any> {
    return this.auctions;
  }

  /**
   * Get estimator list
   * @returns Array<RegisteredTradeObject>
   */
  get estimatorReports(): Array<any> {
    return this.estimator;
  }

  /**
   * Get users list
   * @returns Array<RegisteredTradeObject>
   */
  get usersReports(): Array<any> {
    return this.users;
  }

  /**
   * Get ads list
   * @returns Array<RegisteredTradeObject>
   */
  get adsReports(): Array<any> {
    return this.ads;
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADE_REPORTS](data) {
    this.registeredTrades = data;
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADE_TOP_REPORTS](data) {
    this.registeredTradesTop = data;
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADE_TOP_AREAS_REPORTS](data) {
    this.registeredTradesTopAreas = data;
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADE_PER_QUARTER_REPORTS](data) {
    this.registeredTradesPerQuarter = data;
  }

  @Mutation
  [Mutations.SET_AUCTIONS_REPORTS](data) {
    this.auctions = data;
  }

  @Mutation
  [Mutations.SET_ESTIMATOR_REPORTS](data) {
    this.estimator = data;
  }

  @Mutation
  [Mutations.SET_ADS_REPORTS](data) {
    this.ads = data;
  }

  @Mutation
  [Mutations.SET_USERS_REPORTS](data) {
    this.users = data;
  }
  @Mutation
  [Mutations.SET_REGISTERED_TRADES_DESC](data) {
    this.registeredTradesDesc = data.data;
  }

  @Action
  [Actions.GET_ADS_REPORTS](obj) {
    const params = {
      categoryId: 0,
      propertyCategoryId: 0,
      propertyTypeId: 0,
      areaId: 0,
    };

    // ApiService.setAuthorizationHeader();

    if (obj && obj.value.adTypeId) params.categoryId = obj.value.adTypeId;

    if (obj && obj.value.propertyTypesCategoryId)
      params.propertyCategoryId = obj.value.propertyTypesCategoryId;
    if (obj && obj.value.propertyTypeId)
      params.propertyTypeId = obj.value.propertyTypeId;
    if (obj && obj.value.areaId) params.areaId = obj.value.areaId;

    return ApiService.query('/reports/ads-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_ADS_REPORTS, data);
      }
    );
  }

  @Action
  [Actions.GET_USERS_REPORTS](obj) {
    const params = { dateFrom: '', dateTo: '' };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    ApiService.setAuthorizationHeader();
    return ApiService.query('/reports/user-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_USERS_REPORTS, data);
      }
    );
  }

  @Action
  [Actions.GET_REGISTERED_TRADE_REPORTS](obj) {
    try {
      const params = { dateFrom: '', dateTo: '' };

      if (obj && obj.dateFrom) params.dateFrom = String(obj.dateFrom);

      if (obj && obj.dateTo) params.dateTo = String(obj.dateTo);

      if (obj && obj.areaId) params['areaId'] = Number(obj.areaId);
      if (obj && obj.size) params['size'] = Number(obj.size);
      if (obj && obj.priceFrom) params['priceFrom'] = Number(obj.priceFrom);
      if (obj && obj.priceTo) params['priceTo'] = Number(obj.priceTo);
      if (obj && obj.propertyStatusId)
        params['propertyStatusId'] = obj.propertyStatusId;
      if (obj && obj.propertyTypeId)
        params['propertyTypeId'] = Number(obj.propertyTypeId);
      if (obj && obj.propertySoldStatus)
        params['propertySoldStatus'] = obj.propertySoldStatus;

      ApiService.setAuthorizationHeader();
      return ApiService.query('/reports/registered-trades-count', {
        params,
      }).then(({ data }) => {
        this.context.commit(Mutations.SET_REGISTERED_TRADE_REPORTS, data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  @Action
  [Actions.GET_REGISTERED_TRADE_TOP_REPORTS](obj) {
    try {
      // console.log(obj);
      const params = {
        dateFrom: '',
        dateTo: '',
        propertyStatusId: 0,
      };

      if (obj && obj.value.dateFrom)
        params.dateFrom = String(obj.value.dateFrom);

      if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);
      if (obj && obj.value.propertyStatusId)
        params.propertyStatusId = obj.value.propertyStatusId;

      if (obj && obj.value.selectedArea.length > 0) {
        debugger;
        params['areasIds'] = obj.value.selectedArea;
      }
      ApiService.setAuthorizationHeader();
      return ApiService.query('/reports/registered-trades-top', {
        params,
      }).then(({ data }) => {
        this.context.commit(Mutations.SET_REGISTERED_TRADE_TOP_REPORTS, data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  @Action
  [Actions.GET_REGISTERED_TRADE_PER_QUARTER_REPORTS](obj) {
    const params = { dateFrom: '', dateTo: '' };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    ApiService.setAuthorizationHeader();
    return ApiService.query('/reports/registered-trades-per-count', {
      params,
    }).then(({ data }) => {
      this.context.commit(
        Mutations.SET_REGISTERED_TRADE_PER_QUARTER_REPORTS,
        data
      );
    });
  }

  @Action
  [Actions.GET_AUCTIONS_REPORTS](obj) {
    const params = { dateFrom: '', dateTo: '' };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    ApiService.setAuthorizationHeader();
    return ApiService.query('/reports/auction-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_AUCTIONS_REPORTS, data);
      }
    );
  }

  @Action
  [Actions.GET_ESTIMATOR_REPORTS](obj) {
    const params = { dateFrom: '03/05/2023', dateTo: '' };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    ApiService.setAuthorizationHeader();
    return ApiService.query('/reports/estimator-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_ESTIMATOR_REPORTS, data);
      }
    );
  }
  @Action
  [Actions.GET_REGISTERED_TRADE_TOP_AREAS_REPORTS](obj) {
    const params = { dateFrom: '', dateTo: '' };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    if (obj && obj.value.propertyStatusId)
      params['propertyStatusId'] = obj.value.propertyStatusId;

    ApiService.setAuthorizationHeader();
    return ApiService.query('/reports/registered-trades-top-areas-count', {
      params,
    }).then(({ data }) => {
      this.context.commit(
        Mutations.SET_REGISTERED_TRADE_TOP_AREAS_REPORTS,
        data
      );
    });
  }

  @Action
  [Actions.GET_REGISTERED_TRADES_DESC]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/reports/registered-trades-property-status')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_REGISTERED_TRADES_DESC, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
