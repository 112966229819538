import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface Area {
  id: number;
  areaName: string;
}

export interface FactorValue {
  factorId: number;
  factorOptionId: number;
  factorTitle: string;
  id: number;
  slug: string;
  title: string;
  value: string;
}

export interface AreaFactorValues {
  id: number;
  title: string;
  options: Array<FactorValue>;
}

export interface FactorOptionsListInfo {
  areaFactorsValues: Array<AreaFactorValues>;
  errors: unknown;
}

@Module
export default class FactorOptionsModule
  extends VuexModule
  implements FactorOptionsListInfo
{
  areaFactorsValues = [] as Array<AreaFactorValues>;
  comparedAreaFactorsValues = [] as Array<AreaFactorValues>;
  secondComparedAreaFactorsValues = [] as Array<AreaFactorValues>;
  selectedArea = {} as Area;
  errors = {};

  /**
   * Get area factors values
   * @returns Array<AreaFactorValues>
   */
  get areaOptionsValues(): Array<AreaFactorValues> {
    return this.areaFactorsValues;
  }
  /**
   * Get area factors values
   * @returns Array<AreaFactorValues>
   */
  get compareAreaOptionsValues(): Array<AreaFactorValues> {
    return this.comparedAreaFactorsValues;
  }

  /**
   * Get area factors values
   * @returns Array<AreaFactorValues>
   */
  get secondCompareAreaOptionsValues(): Array<AreaFactorValues> {
    return this.secondComparedAreaFactorsValues;
  }

  /**
   * Get area factors values
   * @returns Array<AreaFactorValues>
   */
  get estimatorSelectedArea(): Area {
    return this.selectedArea;
  }

  @Mutation
  [Mutations.SET_IVE_AREA_ESTIMATIONS](data) {
    this.selectedArea = { ...data.data.areas, id: data.data.id };
    this.areaFactorsValues = data.data.areaFactorsValues;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_COMPARE_IVE_AREA_ESTIMATIONS](data) {
    this.comparedAreaFactorsValues = data.data.areaFactorsValues;
  }

  @Mutation
  [Mutations.SET_SECOND_COMPARE_IVE_AREA_ESTIMATIONS](data) {
    this.secondComparedAreaFactorsValues = data.data.areaFactorsValues;
  }

  @Mutation
  [Mutations.RESET_IVE_AREA_ESTIMATIONS]() {
    this.areaFactorsValues = [] as Array<AreaFactorValues>;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_IVE_VALUE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/instant-value-estimator', data)
      .then(() => {
        this.context.commit(Mutations.RESET_IVE_AREA_ESTIMATIONS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IVE_VALUE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/instant-value-estimator', id)
      .then(() => {
        // this.context.commit(Mutations.RESET_IVE_FACTOR_OPTIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IVE_VALUE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/instant-value-estimator', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IVE_AREA_ESTIMATIONS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_IVE_FACTOR_ESTIMATION_OPTIONS]({ areaId, factorId }) {
    ApiService.setAuthorizationHeader();
    const params = { factorId };
    return ApiService.query(
      `/instant-value-estimator/ive-factors-options/${areaId}`,
      { params }
    );
  }

  @Action
  [Actions.GET_IVE_AREA_ESTIMATIONS]({ areaId, mutation, factors }) {
    ApiService.setAuthorizationHeader();
    const factorIds = Object.keys(factors)
      .filter((value) => !isNaN(Number(value)))
      .join(',');
    const params = { factorIds };

    return ApiService.query(`instant-value-estimator/${areaId}`, { params })
      .then(({ data }) => {
        const areaFactorsValues = [] as Array<AreaFactorValues>;
        data.data.iveFactorsConfig.forEach((c) => {
          const fIndex = areaFactorsValues.findIndex(
            (f) => f['id'] === c.factorId
          );
          if (fIndex < 0) {
            const factor = {
              id: c.factorId,
              title: c.factorTitle,
              options: [c],
              areaName: data.data.areas.areaName,
            };
            areaFactorsValues.push(factor);
          } else {
            areaFactorsValues[fIndex].options.push(c);
          }
        });

        data.data.areaFactorsValues = areaFactorsValues.sort(
          (a, b) => parseFloat(String(a.id)) - parseFloat(String(b.id))
        );
        this.context.commit(mutation, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
