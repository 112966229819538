import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface RegisteredTradeObject {
  id: number;
  transactionDate: Date;
  transactionNumber: number;
  size: number;
  description: string;
  governorate: string;
  areaName: string;
  propertyTypeName: string;
  notes: string;
  price: number;
  pricePerM2: number;
  areaId: number;
  propertyTypeId: number;
  percentage: string;
  block: string;
  descriptionEn: string;
  descriptionAr: string;
  notesEn: string;
  notesAr: string;
}

export interface PropertyTypesObject {
  id: number;
  title: string;
  titleEn: string;
  titleAr: string;
  slug: string;
}

export interface RegisteredTradesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  registeredTrades: Array<RegisteredTradeObject>;
  errors: unknown;
}
export interface RegisteredTradesFilterInfo {
  dateFrom: string;
  dateTo: string;
  areaId: string;
  priceFrom: undefined | number;
  priceTo: undefined | number;
  size: undefined | number;
  noteAr: string;
  propertyStatusId: string;
  propertyTypeId: string;
}

@Module
export default class RegisteredTradesModule
  extends VuexModule
  implements RegisteredTradesListInfo
{
  registeredTrades = [] as Array<RegisteredTradeObject>;
  propertyTypes = [] as Array<PropertyTypesObject>;
  registeredFilter = {} as RegisteredTradesFilterInfo;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get registeredTrades list
   * @returns Array<RegisteredTradeObject>
   */
  get registeredTradesList(): Array<RegisteredTradeObject> {
    if (this.registeredTrades.length > 0)
      this.registeredTrades[0]['page'] = this.currentPage;
    return this.registeredTrades;
  }

  /**
   * Get total items
   * @returns number
   */
  get registeredTradesListCount(): number {
    return this.totalItems;
  }

  /**
   * Get property type list
   * @returns number
   */
  get propertyTypesAllList(): Array<PropertyTypesObject> {
    return this.propertyTypes;
  }
  @Mutation
  [Mutations.SET_REGISTERED_TRADES](data) {
    this.registeredTrades = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_PROPERTY_TYPES](data) {
    this.propertyTypes = data.data;
  }
  @Mutation
  [Mutations.SET_REGISTERED_TRADES_CURRENT_PAGE](page) {
    this.currentPage = page;
  }
  @Mutation
  [Mutations.SET_REGISTERED_TRADES_FILTER](obj) {
    this.registeredFilter.dateFrom = String(obj.dateFrom);
    this.registeredFilter.dateTo = String(obj.dateTo);
    this.registeredFilter.areaId = String(obj.areaId);
    this.registeredFilter.noteAr = String(obj.noteAr);
    this.registeredFilter.propertyStatusId = String(obj.propertyStatusId);
    this.registeredFilter.propertyTypeId = String(obj.propertyTypeId);
    this.registeredFilter.size = Number(obj.size);
    this.registeredFilter.priceFrom = Number(obj.priceFrom);
    this.registeredFilter.priceTo = Number(obj.priceTo);
  }

  @Mutation
  [Mutations.RESET_REGISTERED_TRADES_LIST]() {
    this.registeredTrades = [] as Array<RegisteredTradeObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_REGISTERED_TRADE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/registered-trades', data)
      .then(() => {
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_REGISTERED_TRADE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/registered-trades', id)
      .then(() => {
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_REGISTERED_TRADE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/registered-trades', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_REGISTERED_TRADE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_REGISTERED_TRADES_CURRENT_PAGE, val);
  }
  @Action
  [Actions.UPDATE_REGISTERED_TRADE_FILTER](val) {
    this.context.commit(Mutations.SET_REGISTERED_TRADES_FILTER, val);
  }

  @Action
  [Actions.GET_REGISTERED_TRADE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/registered-trades', id);
  }

  @Action
  [Actions.GET_REGISTERED_TRADES](options: { limit?: number } = {}) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      limit: options.limit || 10,
      dateFrom: this.registeredFilter.dateFrom,
      dateTo: this.registeredFilter.dateTo,
      areaId: Number(this.registeredFilter.areaId),
      priceFrom: this.registeredFilter.priceFrom,
      priceTo: this.registeredFilter.priceTo,
      noteAr: this.registeredFilter.noteAr,
      propertyStatusId: Number(this.registeredFilter.propertyStatusId),
      propertyTypeId: Number(this.registeredFilter.propertyTypeId),
      size: this.registeredFilter.size,
    };
    return ApiService.query('/registered-trades', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.transactionDate = new Date(r.transactionDate)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_REGISTERED_TRADES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_PROPERTY_TYPE](
    options: { type?: string; propertyTypesCategoryId: number } = {
      type: 'all',
      propertyTypesCategoryId: 0,
    }
  ) {
    const params = {
      type: options.type,
      propertyTypesCategoryId: options.propertyTypesCategoryId
        ? options.propertyTypesCategoryId
        : 0,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/property-types/findAllDdl', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_PROPERTY_TYPES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
