import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface Appraisal {
  id: number;
  applicantName: string;
  applicantMobile: string;
  applicantEmail: string;
  remarks: string;
  isBank: boolean;
  additionalDetails: string;
  isActive: boolean | null;
  appraisalType: string | null;
  applicantType: number;
  size: number;
  bankId: number;
  status: string | null;
  price: number | null;
  appraiserName: string;
  appraiserPhone: string;
  appraiserEmail: string;
  areaName: string;
  statusTitle: string;
}

export interface AppraisalsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  appraisals: Array<Appraisal>;
  errors: unknown;
}

@Module
export default class AppraisalsModule
  extends VuexModule
  implements AppraisalsListInfo
{
  appraisals = [] as Array<Appraisal>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get appraisals list
   * @returns Array<Appraisals>
   */
  get appraisalsList(): Array<Appraisal> {
    return this.appraisals;
  }

  /**
   * Get total items
   * @returns number
   */
  get appraisalsListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_APPRAISALS](data) {
    this.appraisals = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_APPRAISALS_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_APPRAISALS_LIST]() {
    this.appraisals = [] as Array<Appraisal>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_APPRAISALS_STORE]() {
    this.context.commit(Mutations.RESET_APPRAISALS_LIST);
  }

  @Action
  [Actions.DELETE_APPRAISAL](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('official-appraisals', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ADMINS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_APPRAISAL]({ id, data, isAppraiser = false }) {
    ApiService.setAuthorizationHeader();
    const url = isAppraiser
      ? '/appraisals-requests/appraiser'
      : '/appraisals-requests';
    return ApiService.update(url, id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ADMINS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_APPRAISAL_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_APPRAISALS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_APPRAISAL]({ id, isAppraiser = false }) {
    ApiService.setAuthorizationHeader();
    const url = isAppraiser
      ? '/appraisals-requests/appraiser'
      : '/appraisals-requests';
    return ApiService.get(url, id);
  }

  @Action
  [Actions.GET_APPRAISALS](isAppraiser = false) {
    ApiService.setAuthorizationHeader();
    const url = isAppraiser
      ? '/appraisals-requests/appraiser'
      : '/appraisals-requests';
    return ApiService.query(url, {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_APPRAISALS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
