import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AppraiserUser {
  id: number;
  name: string;
  email: string;
  phone: number;
  bank_account_details?: string;
  personPhone?: string;
  personName?: string;
  is_active?: string;
}

export interface AppraisersListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  appraisers: Array<AppraiserUser>;
  errors: unknown;
}

@Module
export default class AppraisersModule
  extends VuexModule
  implements AppraisersListInfo
{
  appraisers = [] as Array<AppraiserUser>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get appraisers list
   * @returns Array<AppraiserUser>
   */
  get appraisersList(): Array<AppraiserUser> {
    return this.appraisers;
  }

  /**
   * Get total items
   * @returns number
   */
  get appraisersListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_APPRAISERS](data) {
    this.appraisers = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_APPRAISER_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_APPRAISERS_LIST]() {
    this.appraisers = [] as Array<AppraiserUser>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_APPRAISERS_STORE]() {
    this.context.commit(Mutations.RESET_APPRAISERS_LIST);
  }

  @Action
  [Actions.CREATE_APPRAISER_ACCOUNT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/official-appraisal/appraisers', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_APPRAISERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_APPRAISER_BANKS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/official-appraisal/appraisers/update-banks', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_APPRAISERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_APPRAISER_PRICING](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(
      '/official-appraisal/appraisers/update-appraisers-pricing',
      data
    )
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_APPRAISERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_APPRAISER_ACCOUNT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/official-appraisal/appraisers', id)
      .then(() => {
        this.context.commit(Mutations.RESET_APPRAISERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_APPRAISER_ACCOUNT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/official-appraisal/appraisers', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_APPRAISERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_APPRAISER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_APPRAISER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_APPRAISER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/official-appraisal/appraisers', id);
  }

  @Action
  [Actions.GET_APPRAISERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/official-appraisal/appraisers', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_APPRAISERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
