import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface LocationsConfigObj {
  id: number;
  areaId: number;
  locationId: number;
  pricepermeter: number;
  locationsTitle: string;
  locationTypeId: number;
}

export interface IreCommonSizeQualityConfigObj {
  id: number;
  areaId: number;
  sizeId: number;
  qualityId: number;
  isBase: boolean;
  value: number;
  increasePercentage: number;
  priceRentalPerMeter: number;
  calculationPercentage: number;
  isCustom: boolean;
  qualityTitle: string;
  sizeTitle: string;
}

export interface PropertyAgeConfig {
  id: number;
  areaId: number;
  sizeId: number;
  propertyAgeId: number;
  isBase: boolean;
  value: number;
  increasePercentage: number;
  decreasedPercentage: number;
  calculationPercentage: number;
  isCustom: boolean;
  qualityTitle: string;
  sizeTitle: string;
}
export interface IreCommonAreaObject {
  id: number;
  areaId: number;
  isActive: boolean;
  sort: number;
  isSeaView: boolean;
  areaName: string;
  baseValue: number;
  editPercentages: boolean;
  firstIncreasePercentage: number;
  secondIncreasePercentage: number;
  firstDecreasedPercentage: number;
  secondDecreasedPercentage: number;
  ireCommonSizeQualityConfig: IreCommonSizeQualityConfigObj[];
  propertyAgeConfig: PropertyAgeConfig[];
  locationsConfig: LocationsConfigObj[];
  customUpdatedAt: string;
}

export interface IreCommonAreaAllListObject {
  id: number;
  titleEn: string;
  areas: any;
  areaName: string;
}
export interface AreaObject {
  id: number;
  title: string;
}

export interface IreCommonAreasListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  errors: unknown;
}

@Module
export default class IreCommonAreasModule
  extends VuexModule
  implements IreCommonAreasListInfo
{
  ireCommonAreas = [] as Array<IreCommonAreaObject>;
  ireCommonAllAreas = [] as Array<IreCommonAreaAllListObject>;
  ireCommonUnitComp = [] as Array<any>; // ddl for size Desc
  areasFilter = {} as IreCommonAreaObject;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get ireCommonAreas list
   * @returns Array<IreCommonAreasObject>
   */
  get ireCommonAreasList(): Array<IreCommonAreaObject> {
    if (this.ireCommonAreas.length > 0)
      this.ireCommonAreas[0]['page'] = this.currentPage;
    return this.ireCommonAreas;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get ireCommonAreasAllList(): Array<IreCommonAreaAllListObject> {
    return this.ireCommonAllAreas;
  }

  /**
   * Get all ive areas list
   * @returns Array<AreaObject>
   */
  get ireCommonAreaFilter(): IreCommonAreaObject {
    return this.areasFilter;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireCommonAreasListCount(): number {
    return this.totalItems;
  }

  get getireCommonUnitComp(): Array<any> {
    return this.ireCommonUnitComp;
  } // ddl for size desc

  @Mutation
  [Mutations.SET_IRE_COMMON_AREA_FILTER](obj) {
    this.areasFilter.areaId = obj.areaId;
  }

  @Action
  [Actions.UPDATE_IRE_COMMON_AREA_FILTER](val) {
    this.context.commit(Mutations.SET_IRE_COMMON_AREA_FILTER, val);
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_AREAS](data) {
    this.ireCommonAreas = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_IRE_COMMON_AREAS](data) {
    this.ireCommonAllAreas = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_IRE_UNIT_COMP](data) {
    this.ireCommonUnitComp = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_AREA_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IRE_COMMON_AREAS_LIST]() {
    this.ireCommonAreas = [] as Array<IreCommonAreaObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.UPDATE_IRE_COMMON_CONFIG_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_COMMON_CONFIG_CURRENT_PAGE, val);
  }

  @Action
  [Actions.CREATE_IRE_COMMON_AREA](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-common/areas', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_IRE_COMMON_AREAS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IRE_COMMON_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-common/areas', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_COMMON_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_COMMON_AREA]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-common/areas', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_COMMON_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_COMMON_AREA_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_COMMON_AREA_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IRE_COMMON_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-common/areas', id);
  }

  @Action
  [Actions.GET_IRE_COMMON_AREAS](
    options: { limit?: number; type?: number } = { limit: 10, type: 3 }
  ) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      limit: options.limit,
      areaId: this.areasFilter.areaId ? this.areasFilter.areaId : '',
      type: options.type,
    };
    return ApiService.query('/ire-common/areas', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_IRE_COMMON_AREAS](options) {
    const params = {
      type: options.type,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/ire-common/areas/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IRE_COMMON_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.UPDATE_IRE_SIZE_CONFIG_OPTIONS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-common/ire-size-v2-config', data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET__LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  // @Action
  // [Actions.UPDATE_IRE_PROPERTY_AGE_CONFIG_OPTIONS](data) {
  //   ApiService.setAuthorizationHeader();
  //   return ApiService.post('/ire-common/ire-property-v2-age-config', data)
  //     .then(({ data }) => {
  //       // this.context.commit(Mutations.RESET__LIST);
  //       return data.data;
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, {
  //         [response.statusText]: [response.data.message],
  //       });
  //     });
  // }

  @Action
  [Actions.UPDATE_SHOP_CONFIG](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-common/ire-shop-basement-values', data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET_APPRAISERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
