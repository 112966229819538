import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface PropertyCategorObject {
  id: number;
  slug: string;
  titleEn: string;
  titleAr: string;
  isActive: boolean;
  adType: number;
}

export interface PropertyCategoryListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  propertyCategory: Array<PropertyCategorObject>;
  errors: unknown;
}
export interface PropertyCategoryFilter {
  adTypeId: number;
}

@Module
export default class PropertyCategoryModule
  extends VuexModule
  implements PropertyCategoryListInfo
{
  propertyCategory = [] as Array<PropertyCategorObject>;
  propertyDdlCategory = [] as Array<PropertyCategorObject>;
  propertyCategoryFilter = {} as PropertyCategoryFilter;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get propertyCategory list
   * @returns Array<PropertyCategorObject>
   */
  get propertyCategoryList(): Array<PropertyCategorObject> {
    if (this.propertyCategory.length > 0)
      this.propertyCategory[0]['page'] = this.currentPage;
    return this.propertyCategory;
  }

  /**
   * Get propertyAllCategoryList list
   * @returns Array<PropertyCategorObject>
   */
  get propertyAllCategoryList(): Array<PropertyCategorObject> {
    return this.propertyDdlCategory;
  }

  /**
   * Get total items
   * @returns number
   */
  get propertyCategoryCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_PROPERTY_CATEGORIES](data) {
    this.propertyCategory = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_PROPERTY_CATEGORY_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_PROPERTY_CATEGORIES_LIST]() {
    this.propertyCategory = [] as Array<PropertyCategorObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_PROPERTY_CATEGORIES](data) {
    this.propertyDdlCategory = data.data;
  }

  @Action
  [Actions.RESET_PROPERTY_CATEGORIES_STORE]() {
    this.context.commit(Mutations.RESET_PROPERTY_CATEGORIES_LIST);
  }

  @Action
  [Actions.CREATE_PROPERTY_CATEGORY](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/property-category', data)
      .then(() => {
        this.context.commit(Mutations.RESET_PROPERTY_CATEGORIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_PROPERTY_CATEGORY]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/property-category', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_PROPERTY_CATEGORIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_PROPERTY_CATEGORY_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_PROPERTY_CATEGORY_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_PROPERTY_CATEGORY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/property-category', id);
  }

  @Action
  [Actions.GET_PROPERTY_CATEGORIES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/property-category', {
      params: {
        page: this.currentPage,
        adTypeId: this.propertyCategoryFilter.adTypeId
          ? this.propertyCategoryFilter.adTypeId
          : 0,
      },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROPERTY_CATEGORIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_ALL_PROPERTY_CATEGORIES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/property-category/findAllDdl')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_PROPERTY_CATEGORIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID](
    options: { adType?: number } = {}
  ) {
    const params = {
      adType: options.adType ? options.adType : 0,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/property-category/findAllDdl', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_PROPERTY_CATEGORIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.UPDATE_PROPERTY_CATEGORIES_FILTER](val) {
    this.context.commit(Mutations.SET_PROPERTY_CATEGORIES_FILTER, val);
  }

  @Mutation
  [Mutations.SET_PROPERTY_CATEGORIES_FILTER](obj) {
    this.propertyCategoryFilter.adTypeId = Number(obj.adTypeId);
  }
}
