import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Area } from '../IVE/EstimatorsModule';

export interface FinanceFundsRequestObject {
  id: number;
  amount: number;
  propertyValue: number;
  name: string;
  phone: string;
  propertyCategory: string;
  screen: string;
  entityId: number;
  siteUrl: string;
  createdAt: Date;
  isSent: boolean;
  LeadID: string;
  property_category: any;
  salary: number;
  obligations: number;
  repaymentPeriod: number;
  isBankCustomer: boolean;
  res: any;
}
export interface FinanceFundRequestListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  finances: Array<FinanceFundsRequestObject>;
  errors: unknown;
}
@Module
export default class FinanceFundsRequestModule
  extends VuexModule
  implements FinanceFundRequestListInfo
{
  finances = [] as Array<FinanceFundsRequestObject>;
  FinanceFunds = [] as Array<FinanceFundsRequestObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get Finances list
   * @returns Array<FinancesObject>
   */
  get financesList(): Array<FinanceFundsRequestObject> {
    return this.finances;
  }

  /**
   * Get total items
   * @returns number
   */
  get financesListCount(): number {
    return this.totalItems;
  }

  get exportedFinanceFundsinList(): Array<FinanceFundsRequestObject> {
    return this.FinanceFunds;
  }

  @Mutation
  [Mutations.SET_EXPORT_FINANCES_FUNDS_REQUEST](data) {
    this.FinanceFunds = data.data;
  }

  @Mutation
  [Mutations.SET_FINANCES_FUNDS_REQUEST](data) {
    this.finances = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.RESET_FINANCES_FUNDS_REQUEST_LIST]() {
    this.finances = [] as Array<FinanceFundsRequestObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_FINANCES_FUNDS_REQUEST_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Action
  [Actions.RESET_FINANCES_FUNDS_REQUEST_STORE]() {
    this.context.commit(Mutations.RESET_FINANCES_FUNDS_REQUEST_LIST);
  }

  @Action
  [Actions.UPDATE_FINANCES_FUNDS_REQUEST_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_FINANCES_FUNDS_REQUEST_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_FINANCE_FUNDS_REQUEST](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/finance-requests', id);
  }

  @Action
  [Actions.GET_FINANCES_FUNDS_REQUESTS]() {
    debugger;
    ApiService.setAuthorizationHeader();
    return ApiService.query('/finance-requests', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          (r.LeadID = r.res && r.res.LeadID ? r.res.LeadID : ''),
            (r.propertyCategory = r.property_category
              ? r.property_category.titleEn
              : '');
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_FINANCES_FUNDS_REQUEST, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR,{
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.EXPORT_FINANCES_FUNDS_REQUEST]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/finance-requests/export')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXPORT_FINANCES_FUNDS_REQUEST, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
