import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface RegisteredTradesFilterInfo {
  dateFrom: string;
  dateTo: string;
  areaId: string;
  propertyStatusId: string;
  propertyTypeId: string;
}

@Module
export default class DashboardModule extends VuexModule {
  registeredTrades = [] as Array<any>;
  auctions = [] as Array<any>;
  estimator = [] as Array<any>;
  users = [] as Array<any>;
  ads = [] as Array<any>;

  registeredFilter = {} as RegisteredTradesFilterInfo;
  adsFilter = {} as RegisteredTradesFilterInfo;
  auctionsFilter = {} as RegisteredTradesFilterInfo;
  usersFilter = {} as RegisteredTradesFilterInfo;
  estimatorFilter = {} as RegisteredTradesFilterInfo;

  /**
   * Get registeredTrades list
   * @returns Array<RegisteredTradeObject>
   */
  get registeredTradesDashboard(): Array<any> {
    return this.registeredTrades;
  }

  /**
   * Get filter
   * @returns Array<RegisteredTradeObject>
   */
  get registeredFilterValue(): RegisteredTradesFilterInfo {
    return this.registeredFilter;
  }

  get adsFilterValue(): RegisteredTradesFilterInfo {
    return this.adsFilter;
  }

  get auctionsFilterValue(): RegisteredTradesFilterInfo {
    return this.auctionsFilter;
  }

  get usersFilterValue(): RegisteredTradesFilterInfo {
    return this.usersFilter;
  }

  get estimatorFilterValue(): RegisteredTradesFilterInfo {
    return this.estimatorFilter;
  }

  /**
   * Get auctions list
   * @returns Array<RegisteredTradeObject>
   */
  get auctionsDashboard(): Array<any> {
    return this.auctions;
  }

  /**
   * Get estimator list
   * @returns Array<RegisteredTradeObject>
   */
  get estimatorDashboard(): Array<any> {
    return this.estimator;
  }

  /**
   * Get users list
   * @returns Array<RegisteredTradeObject>
   */
  get usersDashboard(): Array<any> {
    return this.users;
  }

  /**
   * Get ads list
   * @returns Array<RegisteredTradeObject>
   */
  get adsDashboard(): Array<any> {
    return this.ads;
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADE_DASHBOARD](data) {
    this.registeredTrades = data;
  }

  @Mutation
  [Mutations.SET_AUCTIONS_DASHBOARD](data) {
    this.auctions = data;
  }

  @Mutation
  [Mutations.SET_ESTIMATOR_DASHBOARD](data) {
    this.estimator = data;
  }

  @Mutation
  [Mutations.SET_ADS_DASHBOARD](data) {
    this.ads = data;
  }

  @Mutation
  [Mutations.SET_USERS_DASHBOARD](data) {
    this.users = data;
  }

  @Action
  [Actions.GET_ADS_DASHBOARD](obj) {
    const params = { dateFrom: '', dateTo: '' };

    ApiService.setAuthorizationHeader();

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    return ApiService.query('/dashboard/ads-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_ADS_DASHBOARD, data);
      }
    );
  }

  @Action
  [Actions.GET_USERS_DASHBOARD](obj) {
    const params = { dateFrom: '', dateTo: '' };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    ApiService.setAuthorizationHeader();
    return ApiService.query('/dashboard/user-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_USERS_DASHBOARD, data);
      }
    );
  }

  @Action
  [Actions.GET_REGISTERED_TRADE_DASHBOARD](obj) {
    // console.log(obj);
    const params = {
      dateFrom: '',
      dateTo: '',
      areaId: 0,
      propertyTypeId: 0,
      propertyStatusId: 0,
    };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);
    if (obj && obj.value.areaId) params.areaId = Number(obj.value.areaId);
    if (obj && obj.value.propertyTypeId)
      params.propertyTypeId = obj.value.propertyTypeId;
    if (obj && obj.value.propertyStatusId)
      params.propertyStatusId = obj.value.propertyStatusId;

    debugger;
    console.log(params);
    ApiService.setAuthorizationHeader();
    return ApiService.query('/dashboard/registered-trades-count', {
      params,
    }).then(({ data }) => {
      debugger;
      this.context.commit(Mutations.SET_REGISTERED_TRADE_DASHBOARD, data);
    });
  }

  @Action
  [Actions.GET_AUCTIONS_DASHBOARD](obj) {
    const params = { dateFrom: '', dateTo: '' };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    ApiService.setAuthorizationHeader();
    return ApiService.query('/dashboard/auction-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_AUCTIONS_DASHBOARD, data);
      }
    );
  }

  @Action
  [Actions.GET_ESTIMATOR_DASHBOARD](obj) {
    const params = { dateFrom: '03/05/2023', dateTo: '' };

    if (obj && obj.value.dateFrom) params.dateFrom = String(obj.value.dateFrom);

    if (obj && obj.value.dateTo) params.dateTo = String(obj.value.dateTo);

    ApiService.setAuthorizationHeader();
    return ApiService.query('/dashboard/estimator-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_ESTIMATOR_DASHBOARD, data);
      }
    );
  }

  @Action
  [Actions.UPDATE_REGISTERED_TRADE_DASHBOARD_FILTER](val) {
    this.context.commit(Mutations.SET_REGISTERED_TRADES_DASHBOARD_FILTER, val);
  }

  @Action
  [Actions.UPDATE_AUCTIONS_DASHBOARD_FILTER](val) {
    this.context.commit(Mutations.SET_AUCTIONS_DASHBOARD_FILTER, val);
  }

  @Action
  [Actions.UPDATE_ESTIMATOR_DASHBOARD_FILTER](val) {
    this.context.commit(Mutations.SET_ESTIMATOR_DASHBOARD_FILTER, val);
  }

  @Action
  [Actions.UPDATE_USERS_DASHBOARD_FILTER](val) {
    this.context.commit(Mutations.SET_USERS_DASHBOARD_FILTER, val);
  }

  @Action
  [Actions.UPDATE_ADS_DASHBOARD_FILTER](val) {
    this.context.commit(Mutations.SET_ADS_DASHBOARD_FILTER, val);
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADES_DASHBOARD_FILTER](obj) {
    this.registeredFilter.dateFrom = String(obj.dateFrom);
    this.registeredFilter.dateTo = String(obj.dateTo);
    this.registeredFilter.areaId = String(obj.areaId);
    this.registeredFilter.propertyStatusId = String(obj.propertyStatusId);
    this.registeredFilter.propertyTypeId = String(obj.propertyTypeId);
  }

  @Mutation
  [Mutations.SET_AUCTIONS_DASHBOARD_FILTER](obj) {
    this.auctionsFilter.dateFrom = String(obj.dateFrom);
    this.auctionsFilter.dateTo = String(obj.dateTo);
  }

  @Mutation
  [Mutations.SET_ESTIMATOR_DASHBOARD_FILTER](obj) {
    this.estimatorFilter.dateFrom = String(obj.dateFrom);
    this.estimatorFilter.dateTo = String(obj.dateTo);
  }

  @Mutation
  [Mutations.SET_USERS_DASHBOARD_FILTER](obj) {
    this.usersFilter.dateFrom = String(obj.dateFrom);
    this.usersFilter.dateTo = String(obj.dateTo);
  }

  @Mutation
  [Mutations.SET_ADS_DASHBOARD_FILTER](obj) {
    this.adsFilter.dateFrom = String(obj.dateFrom);
    this.adsFilter.dateTo = String(obj.dateTo);
  }
}
