import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface QualityGroupObject {
  id: number;
  titleEn: string;
  titleAr: string;
  slug: string;
  tempWeight: number;
  weight: number;
  isActive: boolean;
  title: string;
  sort: number;
}

export interface qualityGroupsListInfo {
  qualityGroups: Array<QualityGroupObject>;
  errors: unknown;
}

@Module
export default class QualityGroupsModule
  extends VuexModule
  implements qualityGroupsListInfo
{
  qualityGroups = [] as Array<QualityGroupObject>;
  errors = {};

  /**
   * Get Quality Groups list
   * @returns Array<QualityGroupObject>
   */
  get qualityGroupsList(): Array<QualityGroupObject> {
    return this.qualityGroups;
  }

  @Mutation
  [Mutations.SET_QUALITY_GROUPS](data) {
    this.qualityGroups = data.data;
    this.errors = {};
  }

  @Mutation
  [Mutations.RESET_QUALITY_GROUPS_LIST]() {
    this.qualityGroups = [] as Array<QualityGroupObject>;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_QUALITY_GROUP](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ive-fq-groups', data)
      .then(() => {
        this.context.commit(Mutations.RESET_QUALITY_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_QUALITY_GROUP](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ive-fq-groups', id)
      .then(() => {
        this.context.commit(Mutations.RESET_QUALITY_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_QUALITY_GROUP]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-fq-groups', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_QUALITY_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_GROUPS_WEIGHT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-fq-groups/update-groups-weight', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_QUALITY_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_QUALITY_GROUP_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_QUALITY_GROUPS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_QUALITY_GROUP](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-fq-groups', id);
  }

  @Action
  [Actions.GET_QUALITY_GROUPS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-fq-groups')
      .then(({ data }) => {
        data.data = data.data.sort(
          (a, b) => parseFloat(String(a.id)) - parseFloat(String(b.id))
        );
        this.context.commit(Mutations.SET_QUALITY_GROUPS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
