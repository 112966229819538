import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AdminUserRole {
  id: number;
  name: string;
}

export interface AdminUser {
  id: number;
  name: string;
  email: string;
  roldId: number;
  isActive: boolean;
  role: AdminUserRole;
  roleName: string;
}

export interface AdminsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  admins: Array<AdminUser>;
  errors: unknown;
}

@Module
export default class AdminsModule extends VuexModule implements AdminsListInfo {
  admins = [] as Array<AdminUser>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get admins list
   * @returns Array<AdminUser>
   */
  get adminsList(): Array<AdminUser> {
    return this.admins;
  }

  /**
   * Get total items
   * @returns number
   */
  get listCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_ADMIN_USERS](data) {
    this.admins = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ADMIN_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_ADMINS_LIST]() {
    this.admins = [] as Array<AdminUser>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_ADMINS_STORE]() {
    this.context.commit(Mutations.RESET_ADMINS_LIST);
  }

  @Action
  [Actions.CREATE_ADMIN_ACCOUNT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/users', data)
      .then(() => {
        this.context.commit(Mutations.RESET_ADMINS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_ADMIN_ACCOUNT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('users', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ADMINS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_ADMIN_ACCOUNT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('users', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ADMINS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_ADMIN_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_ADMIN_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ADMIN_USER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('users', id);
  }

  @Action
  [Actions.GET_ADMIN_USERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/users', { params: { page: this.currentPage } })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ADMIN_USERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
