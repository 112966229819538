import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IreAreaObject {
  id: number;
  areaId: number;
  electricity: string;
  isActive: boolean;
  sort: number;
  basePrice: number;
  areaName: string;
  customUpdatedAt: string;
}

export interface IreAreaAllListObject {
  id: number;
  areaName: string;
}
export interface AreaObject {
  id: number;
  title: string;
}

export interface IreAreasListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  errors: unknown;
}

@Module
export default class IreAreassModule
  extends VuexModule
  implements IreAreasListInfo
{
  ireAreas = [] as Array<IreAreaObject>;
  ireAllAreas = [] as Array<IreAreaAllListObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get ireAreas list
   * @returns Array<IreAreasObject>
   */
  get ireAreasList(): Array<IreAreaObject> {
    return this.ireAreas;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get ireAreasAllList(): Array<IreAreaAllListObject> {
    return this.ireAllAreas;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireAreasListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_IRE_AREAS](data) {
    this.ireAreas = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_IRE_AREAS](data) {
    this.ireAllAreas = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_AREA_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IRE_AREAS_LIST]() {
    this.ireAreas = [] as Array<IreAreaObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_IRE_AREA](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ire-areas', data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IRE_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-areas', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_AREA]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-areas', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_AREA_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_AREA_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IRE_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-areas', id);
  }

  @Action
  [Actions.GET_IRE_AREAS](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: options.limit };
    return ApiService.query('/ire-areas', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_IRE_AREAS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-areas/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_IRE_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
