import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface PropertyObject {
  id: number;
  name: string;
  nameEn: string;
  nameAr: string;
  isActive: boolean;
}

export interface PropertiesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  properties: Array<PropertyObject>;
  errors: unknown;
}

@Module
export default class PropertiesModule
  extends VuexModule
  implements PropertiesListInfo
{
  properties = [] as Array<PropertyObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get properties list
   * @returns Array<PropertyObject>
   */
  get propertiesList(): Array<PropertyObject> {
    return this.properties;
  }

  /**
   * Get total items
   * @returns number
   */
  get propertiesListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_PROPERTIES](data) {
    this.properties = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_PROPERTY_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_PROPERTIES_LIST]() {
    this.properties = [] as Array<PropertyObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_PROPERTIES_STORE]() {
    this.context.commit(Mutations.RESET_PROPERTIES_LIST);
  }

  @Action
  [Actions.CREATE_PROPERTY](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/appraisal-property-types', data)
      .then(() => {
        this.context.commit(Mutations.RESET_PROPERTIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_PROPERTY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/appraisal-property-types', id)
      .then(() => {
        this.context.commit(Mutations.RESET_PROPERTIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_PROPERTY]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/appraisal-property-types', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_PROPERTIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_PROPERTY_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_PROPERTY_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_PROPERTY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/appraisal-property-types', id);
  }

  @Action
  [Actions.GET_PROPERTIES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/appraisal-property-types', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROPERTIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
