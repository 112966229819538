import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface BannerObject {
  id: number;
  type: string;
  typeList: string;
  slug: string;
  externalLinkEn: string;
  externalLinkAr: string;
  externalLinkWebEn: string;
  externalLinkWebAr: string;
  propertyCategoryId: number;
  entityId: number;
  screen: string;
  sort: number;
  adPriceFrom: number;
  adPriceTo: number;
  thumbnail: string;
}
export interface BannersAssets {
  id: string;
  title: string;
  isRelatedID: boolean;
}

export interface BannerAssetsObject {
  scrrens: Array<BannersAssets>;
  typeSlug: Array<BannersAssets>;
  typeBanners: Array<BannersAssets>;
}

export interface MerchantObject {
  id: number;
  name: string;
}

export interface BannersListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  banners: Array<BannerObject>;
  errors: unknown;
}

@Module
export default class BannersModule
  extends VuexModule
  implements BannersListInfo
{
  banners = [] as Array<BannerObject>;
  bannerAssets = [] as Array<BannerAssetsObject>;
  merchant = [] as Array<MerchantObject>;

  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get banners list
   * @returns Array<BannerObject>
   */
  get bannersList(): Array<BannerObject> {
    return this.banners;
  }
  get bannerAssetsList(): Array<BannerAssetsObject> {
    return this.bannerAssets;
  }
  get merchantList(): Array<MerchantObject> {
    return this.merchant;
  }

  /**
   * Get total items
   * @returns number
   */
  get bannersListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_BANNERS](data) {
    this.banners = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_BANNERS_ASSETS](data) {
    this.bannerAssets = data.data;
  }
  @Mutation
  [Mutations.SET_MERCHANT](data) {
    this.merchant = data.data;
  }

  @Mutation
  [Mutations.SET_BANNERS_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_BANNERS_LIST]() {
    this.banners = [] as Array<BannerObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_BANNERS_STORE]() {
    this.context.commit(Mutations.RESET_BANNERS_LIST);
  }

  @Action
  [Actions.CREATE_BANNER](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/banners', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_BANNERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_BANNER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/banners', id)
      .then(() => {
        this.context.commit(Mutations.RESET_BANNERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_BANNER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/banners', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_BANNERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_BANNER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_BANNERS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_BANNER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/banners', id);
  }

  @Action
  [Actions.GET_BANNERS]() {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: 10 };
    return ApiService.query('/banners', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BANNERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_BANNER_ASSETS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/banners/assets')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BANNERS_ASSETS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        //});
      });
  }

  @Action
  [Actions.GET_MERCHANT]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/members/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MERCHANT, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.UPLOAD_BANNER_IMAGE]({ data, bannerId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/banners/uploadThumbnail/${bannerId}`, data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
