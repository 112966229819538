import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface PropertyTypeObject {
  id: number;
  slug: string;
  titleEn: string;
  titleAr: string;
  isActive: boolean;
}

export interface PropertyTypesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  propertyTypes: Array<PropertyTypeObject>;
  errors: unknown;
}

export interface PropertyTypesInfoFilter {
  type: number;
  propertyCategoryId: number;
  adTypeId: number;
}

@Module
export default class PropertyTypesModule
  extends VuexModule
  implements PropertyTypesListInfo
{
  propertyTypes = [] as Array<PropertyTypeObject>;
  propertyTypesFilter = {} as PropertyTypesInfoFilter;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get propertyTypes list
   * @returns Array<PropertyTypeObject>
   */
  get propertyTypesList(): Array<PropertyTypeObject> {
    if (this.propertyTypes.length > 0)
      this.propertyTypes[0]['page'] = this.currentPage;
    return this.propertyTypes;
  }

  /**
   * Get total items
   * @returns number
   */
  get propertyTypesCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_PROPERTY_TYPES](data) {
    this.propertyTypes = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_PROPERTY_TYPE_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_PROPERTY_TYPES_LIST]() {
    this.propertyTypes = [] as Array<PropertyTypeObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_PROPERTY_TYPES_STORE]() {
    this.context.commit(Mutations.RESET_PROPERTY_TYPES_LIST);
  }

  @Action
  [Actions.CREATE_PROPERTY_TYPE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/property-types', data)
      .then(() => {
        this.context.commit(Mutations.RESET_PROPERTY_TYPES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_PROPERTY_TYPE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/property-types', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_PROPERTY_TYPES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_PROPERTY_TYPE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_PROPERTY_TYPE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_PROPERTY_TYPE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/property-types', id);
  }

  @Action
  [Actions.GET_PROPERTY_TYPES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/property-types', {
      params: {
        page: this.currentPage,
        type:
          this.propertyTypesFilter.type != -1
            ? this.propertyTypesFilter.type
            : -1,
        propertyCategoryId: this.propertyTypesFilter.propertyCategoryId
          ? this.propertyTypesFilter.propertyCategoryId
          : 0,
        adTypeId: this.propertyTypesFilter.adTypeId
          ? this.propertyTypesFilter.adTypeId
          : 0,
      },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROPERTY_TYPES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.UPDATE_PROPERTY_TYPES_FILTER](val) {
    this.context.commit(Mutations.SET_PROPERTY_TYPES_FILTER, val);
  }

  @Mutation
  [Mutations.SET_PROPERTY_TYPES_FILTER](obj) {
    this.propertyTypesFilter.type = obj.type != -1 ? Number(obj.type) : -1;
    this.propertyTypesFilter.propertyCategoryId = Number(
      obj.propertyCategoryId
    );
    this.propertyTypesFilter.adTypeId = Number(obj.adTypeId);
  }
}
