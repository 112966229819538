import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface PropertyType {
  id: number;
  name: string;
  isActive: boolean;
}

export interface PropertyTypesListInfo {
  propertyTypes: Array<PropertyType>;
}

@Module
export default class AppraisersModule
  extends VuexModule
  implements PropertyTypesListInfo
{
  propertyTypes = [] as Array<PropertyType>;

  /**
   * Get propertyTypes list
   * @returns Array<PropertyType>
   */
  get propertysTypeList(): Array<PropertyType> {
    return this.propertyTypes;
  }

  @Mutation
  [Mutations.SET_ALL_PROPERTY_TYPES](data) {
    this.propertyTypes = data.data;
  }

  @Action
  [Actions.GET_ALL_PROPERTY_TYPES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/appraisal-property-types/findAllPropertyTypes')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_PROPERTY_TYPES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
