import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import AdminsModule from '@/store/modules/Members/AdminsModule';
import BodyModule from '@/store/modules/Layout/BodyModule';
import BreadcrumbsModule from '@/store/modules/Layout/BreadcrumbsModule';
import ConfigModule from '@/store/modules/Layout/ConfigModule';
import RolesModule from '@/store/modules/Members/RolesModule';
import PermissionsModule from '@/store/modules/Members/PermissionsModule';
import AppraisalsModule from '@/store/modules/Appraisals/AppraisalsModule';
import BanksModule from '@/store/modules/Members/BanksModule';
import UsersModule from '@/store/modules/Members/UsersModule';
import AppraisersModule from '@/store/modules/Members/AppraisersModule';
import _PropertyTypesModule from '@/store/modules/_PropertyTypesModule';
import AdTypeModule from '@/store/modules/Ads/AdTypesModule';
import PropertyModule from '@/store/modules/Appraisals/PropertyModule';
import FaqModule from '@/store/modules/Settings/FaqsModule';
import AuctionsModule from '@/store/modules/Auctions/AuctionsModule';
import OrganizersModule from '@/store/modules/Auctions/OrganizersModule';
import AreasModule from '@/store/modules/Assets/AreasModule';
import PropertyTypesModule from '@/store/modules/Ads/PropertyTypesModule';
import GroupsModule from '@/store/modules/CCE/GroupsModule';
import CategoriesModule from '@/store/modules/CCE/CategoriesModule';
import SubCategoriesModule from '@/store/modules/CCE/SubCategoriesModule';
import LocationsModule from '@/store/modules/Auctions/LocationsModule';
import SiteSettingsModule from '@/store/modules/Settings/SiteSettingsModule';
import ContactUs from '@/store/modules/Settings/ContactusModule';
import RegisteredTradesModule from '@/store/modules/RegisteredTrades/RegisteredTradesModule';
import RegisteredTradesScrapingModule from '@/store/modules/RegisteredTrades/RegisteredTradesScrapingModule';
import AdsModule from '@/store/modules/Ads/AdsModule';
import AuctionPropertyTypes from '@/store/modules/Auctions/PropertyTypesModule';
import FactorOptionsModule from '@/store/modules/IVE/FactorOptionsModule';
import IveAreasModule from '@/store/modules/IVE/AreasModule';
import IveAnswersModule from '@/store/modules/IVE/AnswersModule';
import EstimatorsModule from '@/store/modules/IVE/EstimatorsModule';
import LoanFundsRequestModule from '@/store/modules/LoanRequests/LoanFundsRequestModule';
import AdServiceModule from '@/store/modules/Subscriptions/AdsServiceModule';
import AdPackageModule from '@/store/modules/Subscriptions/AdsPackageModule';
import QualityRangesModule from '@/store/modules/IVE/QualityRangesModule';
import QualityGroupsModule from '@/store/modules/IVE/QualityGroupsModule';
import QuestionsModule from '@/store/modules/IVE/QuestionsModule';
import BannerModule from '@/store/modules/Assets/BannerModule';
import IreAreasModule from '@/store/modules/IRE/AreasModule';
import IreFactorsModule from '@/store/modules/IRE/FactorsModule';
import IreTypeModule from '@/store/modules/IRE/TypesModule';
import IreSizeModule from '@/store/modules/IRE/SizesModule';
import BankRatesModule from '@/store/modules/BankRatesModule';
import NewsModule from '@/store/modules/Assets/NewsModule';
import HeaderSectionMoudle from '@/store/modules/Settings/HeaderSectionModule';
import DisclaimerMoudle from '@/store/modules/Settings/DisclaimerModule';
import IreV2FactorssModule from '@/store/modules/IRE/IreV2FactorsModule';
import IreV2AreasModule from '@/store/modules/IRE/IreV2AreasModule';
import PropertyCategoryModule from '@/store/modules/Ads/PropertyCategoryModule';
import IreInvestmentAreasModule from '@/store/modules/IRE/IreInvestmentAreasModule';
import IreInvestmentFactorssModule from '@/store/modules/IRE/IreInvestmentFactorsModule';
import DashboardModule from '@/store/modules/DashboardModule';
import ReportsModule from '@/store/modules/ReportsModule';
import IreCommonAreasModule from '@/store/modules/IRE/IreCommonAreasModule';
import IreCommonFactorssModule from '@/store/modules/IRE/IreCommonFactorsModule';
import IreCommonStreetsModule from '@/store/modules/IRE/IreCommonStreetModule';
import FinanceFundsRequestModule from '@/store/modules/FinanceRequests/FinanceFundsRequestModule';
import HomeServicesModule from '@/store/modules/Assets/HomeServicesModule';

config.rawError = true;

const store = createStore({
  modules: {
    DashboardModule,
    EstimatorsModule,
    OrganizersModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    AdminsModule,
    RolesModule,
    PermissionsModule,
    AppraisalsModule,
    BanksModule,
    UsersModule,
    AdTypeModule,
    PropertyModule,
    AppraisersModule,
    _PropertyTypesModule,
    AuctionsModule,
    LocationsModule,
    AreasModule,
    CceGroupsModule: GroupsModule,
    CceCategoriesModule: CategoriesModule,
    CceSubCategoriesModule: SubCategoriesModule,
    PropertyTypesModule,
    FaqModule,
    SiteSettingsModule,
    ContactUs,
    RegisteredTradesModule,
    RegisteredTradesScrapingModule,
    AdsModule,
    AuctionPropertyTypes,
    FactorOptionsModule,
    LoanFundsRequestModule,
    AdServiceModule,
    AdPackageModule,
    IveAreasModule,
    QualityRangesModule,
    BannerModule,
    QualityGroupsModule,
    QuestionsModule,
    IveAnswersModule,
    IreAreasModule,
    IreFactorsModule,
    IreTypeModule,
    IreSizeModule,
    BankRatesModule,
    HeaderSectionMoudle,
    NewsModule,
    DisclaimerMoudle,
    IreV2FactorssModule,
    IreV2AreasModule,
    PropertyCategoryModule,
    IreInvestmentAreasModule,
    IreInvestmentFactorssModule,
    ReportsModule,
    IreCommonAreasModule,
    IreCommonFactorssModule,
    IreCommonStreetsModule,
    FinanceFundsRequestModule,
    HomeServicesModule,
  },
});

export default store;
