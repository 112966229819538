import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface HomeServicesObject {
  id: number;
  titleEn: string;
  titleAr: string;
  descEn: string;
  descAr: string;
  webSlug: string;
  showWeb: boolean;
  isHome: boolean;
  sort: number;
  icon: string;
  webIcon: string;
}
export interface HomeServicesListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  homeServices: Array<HomeServicesObject>;
  errors: unknown;
}

@Module
export default class HomeServicesModule
  extends VuexModule
  implements HomeServicesListInfo
{
  homeServices = [] as Array<HomeServicesObject>;
  registeredDesc = [] as Array<any>;
  registeredNotes = [] as Array<any>;

  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get homeServices list
   * @returns Array<HomeServiceObject>
   */
  get homeServicesList(): Array<HomeServicesObject> {
    return this.homeServices;
  }

  /**
   * Get total items
   * @returns number
   */
  get homeServicesListCount(): number {
    return this.totalItems;
  }
  get getRegisteredDesc() {
    return this.registeredDesc;
  }
  get getRegisteredNotes() {
    return this.registeredNotes;
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADES_ASSEST_DESC](data) {
    this.registeredDesc = data.data;
  }
  @Mutation
  [Mutations.SET_REGISTERED_TRADES_ASSEST_NOTES](data) {
    this.registeredNotes = data.data;
  }
  @Mutation
  [Mutations.SET_HOME_SERVICES](data) {
    this.homeServices = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_HOME_SERVICES_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_HOME_SERVICES_LIST]() {
    this.homeServices = [] as Array<HomeServicesObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_HOME_SERVICES_STORE]() {
    this.context.commit(Mutations.RESET_HOME_SERVICES_LIST);
  }

  @Action
  [Actions.CREATE_HOME_SERVICE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/home-services', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_HOME_SERVICES_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_HOME_SERVICE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/home-services', id)
      .then(() => {
        this.context.commit(Mutations.RESET_HOME_SERVICES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_HOME_SERVICE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/home-services', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_HOME_SERVICES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_HOME_SERVICE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_HOME_SERVICES_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_HOME_SERVICE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/home-services', id);
  }

  @Action
  [Actions.GET_HOME_SERVICES]() {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: 10 };
    return ApiService.query('/home-services', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HOME_SERVICES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.UPLOAD_HOME_SERVICE_IMAGE]({ data, homeServiceId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(
      `/home-services/uploadThumbnail/${homeServiceId}`,
      data
    )
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPLOAD_HOME_SERVICE_IMAGE_WEB]({ data, homeServiceId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(
      `/home-services/uploadThumbnailWeb/${homeServiceId}`,
      data
    )
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_REGISTERED_TRADES_ASSETS](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/registered-trades-assets', id).then(
      ({ data }) => {
        if (id == 6)
          this.context.commit(
            Mutations.SET_REGISTERED_TRADES_ASSEST_DESC,
            data
          );
        else
          this.context.commit(
            Mutations.SET_REGISTERED_TRADES_ASSEST_NOTES,
            data
          );
      }
    );
  }
}
