import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AdsPackageObject {
  id: number;
  titleEn: string;
  titleAr: string;
  descriptionEn: string;
  descriptionAr: string;
  slug: string;
  isActive: boolean;
  price: number;
  duration: number;
  adsServicesPackages: Array<string>;
}

export interface AddPackageListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  AddPackage: Array<AdsPackageObject>;
  errors: unknown;
}
@Module
export default class AdPackageModule
  extends VuexModule
  implements AddPackageListInfo
{
  AddPackage = [] as Array<AdsPackageObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get Ads list
   * @returns Array<AdsObject>
   */
  get adsPackageList(): Array<AdsPackageObject> {
    return this.AddPackage;
  }

  /**
   * Get total items
   * @returns number
   */
  get adsPackagesListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_ADS_PACKAGES](data) {
    this.AddPackage = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ADS_PACKAGES_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_ADS_PACKAGE_LIST]() {
    this.AddPackage = [] as Array<AdsPackageObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_ADS_PACKAGE_STORE]() {
    this.context.commit(Mutations.RESET_ADS_PACKAGE_LIST);
  }

  @Action
  [Actions.UPDATE_ADS_PACKAGES_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_ADS_PACKAGES_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ADS_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ads-packages', id);
  }

  @Action
  [Actions.GET_ADS_PACKAGES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/ads-packages', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_ADS_PACKAGES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.CREATE_AD_PACKAGE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ads-packages', data)
      .then(() => {
        this.context.commit(Mutations.RESET_ADS_PACKAGE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPDATE_ADS_PACKAGE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ads-packages', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ADS_PACKAGE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_AD_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ads-packages', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ADS_PACKAGE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
