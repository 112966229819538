import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AnswerObject {
  id: number;
  questionId: number;
  weightedScore: number;
  score: number;
  titleEn: string;
  titleAr: string;
  title: string;
  slug: string;
  isActive: boolean;
}

export interface AnswersListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  answers: Array<AnswerObject>;
  errors: unknown;
}

@Module
export default class AnswersModule
  extends VuexModule
  implements AnswersListInfo
{
  answers = [] as Array<AnswerObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get banks list
   * @returns Array<AnswerObject>
   */
  get answersList(): Array<AnswerObject> {
    return this.answers;
  }

  /**
   * Get total items
   * @returns number
   */
  get answersListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_ANSWERS](data) {
    this.answers = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ANSWERS_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_ANSWERS_LIST]() {
    this.answers = [] as Array<AnswerObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_ANSWERS_STORE]() {
    this.context.commit(Mutations.RESET_ANSWERS_LIST);
  }

  @Action
  [Actions.CREATE_ANSWER]({ questionId, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/ive-fq-answer/${questionId}`, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ANSWERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_ANSWER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ive-fq-answer', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ANSWERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_ANSWER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ive-fq-answer', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ANSWERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_ANSWER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_ANSWERS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ANSWER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ive-fq-answer/find-one', id);
  }

  @Action
  [Actions.GET_ANSWERS](questionId) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: 10 };
    return ApiService.query(`/ive-fq-answer/${questionId}`, { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ANSWERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
