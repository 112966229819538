import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IreCommonConfigObject {
  id: number;
  title: string;
  percentage: number;
}
export interface IreCommonFactorsConfigObject {
  ireCommonFactorOption: string;
  value: string;
  fOptionId: number;
}

export interface IreCommonFactorValuesObject {
  typeId: number;
  ireCommonFactorsConfig: Array<IreCommonFactorsConfigObject>;
}

export interface FactorOptionObject {
  ireCommonFactorOption: string;
  value: string;
  fOptionId: number;
  id: number;
  title: string;
}

export interface IreCommonFactorsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  ireCommonConfig: Array<IreCommonConfigObject>;
  errors: unknown;
}

@Module
export default class IreCommonFactorssModule
  extends VuexModule
  implements IreCommonFactorsListInfo
{
  ireCommonSizes = [] as Array<any>; // ddl for size
  ireCommonPropertysAge = [] as Array<any>; // ddl for property age
  ireCommonSizesDesc = [] as Array<any>; // ddl for size Desc
  ireCommonConfig = [] as Array<IreCommonConfigObject>;
  ireCommonPropertyTypes = [] as Array<any>;
  ireCommonFloorTypes = [] as Array<any>;
  ireCommonPropertyAge = [] as Array<any>;
  ireCommonPropertyDirection = [] as Array<any>;
  ireCommonPropertyDirectionAll = [] as Array<any>;
  ireCommonFloorTypesAll = [] as Array<any>;
  ireCommonPropertyTypesAll = [] as Array<any>;

  ireCommonQualities = [] as Array<any>;

  ireCommonFactorsAreasValues = [] as Array<IreCommonFactorValuesObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};
  factorType = '';

  /**
   * Get ireCommonConfig list
   * @returns Array<ireCommonConfigObject>
   */
  get ireCommonConfigList(): Array<IreCommonConfigObject> {
    return this.ireCommonConfig;
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_CONFIG_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireCommonConfigListCount(): number {
    return this.totalItems;
  }
  get ireCommonFactorsAreasValuesList(): Array<IreCommonFactorValuesObject> {
    return this.ireCommonFactorsAreasValues;
  }
  get getireCommonPropertyTypes(): Array<any> {
    return this.ireCommonPropertyTypes;
  } // ddl for types
  get getireCommonFloorTypes(): Array<any> {
    return this.ireCommonFloorTypes;
  }

  get getAllIreCommonPropertyTypes(): Array<any> {
    return this.ireCommonPropertyTypesAll;
  } // ddl for types
  get getAllIreCommonFloorTypes(): Array<any> {
    return this.ireCommonFloorTypesAll;
  }

  get getireCommonPropertyAge(): Array<any> {
    return this.ireCommonPropertysAge;
  } // ddl for property age

  get getireCommonPropertyDirection(): Array<any> {
    return this.ireCommonPropertyDirection;
  } // ddl for size desc

  get getAllIreCommonPropertyDirection(): Array<any> {
    return this.ireCommonPropertyDirectionAll;
  } // ddl for size desc

  get getireCommonQualities(): Array<any> {
    return this.ireCommonQualities;
  } // ddl for size desc

  @Mutation
  [Mutations.SET_IRE_COMMON_PROPERTY_TYPE](data) {
    this.ireCommonPropertyTypes = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_FLOOR_TYPE](data) {
    this.ireCommonFloorTypes = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_PROPERTY_AGE](data) {
    this.ireCommonPropertysAge = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_PROPERTY_DIRECTION](data) {
    this.ireCommonPropertyDirection = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_QUALITIES](data) {
    this.ireCommonQualities = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_V2_FACTOR_AREAS_VALUES](data) {
    this.ireCommonFactorsAreasValues = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_IRE_V2_SIZE](data) {
    this.ireCommonSizes = data.data;
  }

  // @Mutation
  // [Mutations.SET_ALL_IRE_V2_PROPERTY_AGE](data) {
  //   this.ireCommonPropertysAge = data.data;
  // }

  @Mutation
  [Mutations.SET_ALL_IRE_V2_SIZE_DESC](data) {
    this.ireCommonSizesDesc = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_CONFIG_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_PROPERTY_TYPE_ALL](data) {
    this.ireCommonPropertyTypesAll = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_FLOOR_TYPES_ALL](data) {
    this.ireCommonFloorTypesAll = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_COMMON_PROPERTY_DIRECTION_ALL](data) {
    this.ireCommonPropertyDirectionAll = data.data;
  }
  @Mutation
  [Mutations.RESET_IRE_COMMON_CONFIG_LIST]() {
    this.ireCommonConfig = [] as Array<IreCommonConfigObject>;
    this.ireCommonPropertyTypes = [] as Array<any>;
    this.ireCommonPropertyAge = [] as Array<any>;
    this.ireCommonPropertyDirection = [] as Array<any>;
    this.ireCommonQualities = [] as Array<any>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.GET_IRE_COMMON_PROPERTY_TYPES](options) {
    const params = {
      type: options.type,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/ire-common/property-types', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_PROPERTY_TYPE, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_IRE_COMMON_FLOOR_TYPES](options) {
    const params = {
      type: options.type,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/ire-common/floor-type', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_FLOOR_TYPE, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_IRE_COMMON_PROPERTY_AGES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-common/property-age')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_PROPERTY_AGE, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_IRE_COMMON_PROPERTY_DIRECTION]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-common/direction')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_PROPERTY_DIRECTION, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_IRE_COMMON_QUALITIES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-common/quality')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_QUALITIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.UPDATE_IRE_COMMON_ENUMS]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update(`/ire-common/${data.endPoint}`, id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_COMMON_CONFIG_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_IRE_COMMON_ENUM](options) {
    const params = { type: options.type };
    ApiService.setAuthorizationHeader();
    return ApiService.query(
      `/ire-common/${options.endPoint + '/' + options.id}`,
      { params }
    );
  }
  @Action
  [Actions.DELETE_IRE_COMMON_PROPERTY_TYPES](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-common/property-types', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_COMMON_CONFIG_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_IRE_COMMON_FLOOR_TYPES](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-common/floor-type', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_COMMON_CONFIG_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.GET_IRE_COMMON_PROPERTY_TYPE_ALL](options) {
    ApiService.setAuthorizationHeader();
    const params = { type: options.type };

    return ApiService.query('/ire-common/property-types/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_PROPERTY_TYPE_ALL, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_IRE_COMMON_FLOOR_TYPES_ALL](options) {
    ApiService.setAuthorizationHeader();
    const params = {
      type: options.type,
      propertyTypeId: options.propertyTypeId,
    };

    return ApiService.query('/ire-common/floor-type/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_COMMON_FLOOR_TYPES_ALL, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_IRE_COMMON_PROPERTY_DIRECTION_ALL](options) {
    ApiService.setAuthorizationHeader();
    const params = {
      type: options.type,
      floorTypeId: options.floorTypeId,
    };
    return ApiService.query('/ire-common/direction/all', { params })
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_IRE_COMMON_PROPERTY_DIRECTION_ALL,
          data
        );
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
