import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface HeaderSectionObject {
  id: number;
  titleEn: string;
  descEn: string;
  descAr: string;
}
export interface HeaderSectionListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  headerSection: Array<HeaderSectionObject>;
  errors: unknown;
}
@Module
export default class HeaderSectionModule
  extends VuexModule
  implements HeaderSectionListInfo
{
  headerSection = [] as Array<HeaderSectionObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};
  adsCount = 0;
  appraisalsRequestsCount = 0;

  /**
   * Get header list
   * @returns Array<BankObject>
   */
  get headerList(): Array<HeaderSectionObject> {
    return this.headerSection;
  }

  /**
   * Get total items
   * @returns number
   */
  get headerListCount(): number {
    return this.totalItems;
  }

  get getAppraisalsRequestsCount(): number {
    return this.appraisalsRequestsCount;
  }
  get getAdsCount(): number {
    return this.adsCount;
  }

  @Mutation
  [Mutations.SET_HEADERS](data) {
    this.headerSection = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_COUNTS](data) {
    this.appraisalsRequestsCount = data.data.appraisalsRequestsCount;
    this.adsCount = data.data.adsCount;
  }
  @Mutation
  [Mutations.RESET_HEADER_LIST]() {
    this.headerSection = [] as Array<HeaderSectionObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_HEADER_SECTION_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Action
  [Actions.UPDATE_HEADER_SECTION_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_HEADER_SECTION_CURRENT_PAGE, val);
  }

  @Action
  [Actions.UPDATE_HEADER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/headers-section', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_HEADER_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_HEADER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/headers-section', id);
  }

  @Action
  [Actions.GET_HEADERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/headers-section', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HEADERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ADS_APPRS_INFO]() {
    ApiService.setAuthorizationHeader();
    ApiService.get('/settings/ads-count-home')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
