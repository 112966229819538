import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CceGroupObject {
  id: number;
  titleEn: string;
  titleAr: string;
  groupId: number;
}

export interface CceAllGroupObject {
  id: number;
  title: string;
}

export interface CceQualityObject {
  id: number;
  title: string;
  price: never;
}

export interface CceGroupsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  cceGroups: Array<CceGroupObject>;
  errors: unknown;
}

@Module
export default class GroupsModule
  extends VuexModule
  implements CceGroupsListInfo
{
  cceGroups = [] as Array<CceGroupObject>;
  cceAllGroups = [] as Array<CceAllGroupObject>;
  cceAllQuality = [] as Array<CceQualityObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get cceGroups list
   * @returns Array<CceGroupObject>
   */
  get cceGroupsList(): Array<CceGroupObject> {
    return this.cceGroups;
  }

  /**
   * Get groups list
   * @returns Array<GroupObject>
   */
  get allCceGroupsList(): Array<CceAllGroupObject> {
    return this.cceAllGroups;
  }
  /**
   * Get quality list
   * @returns Array<GroupObject>
   */
  get allCceQualityList(): Array<CceQualityObject> {
    return this.cceAllQuality;
  }

  /**
   * Get total items
   * @returns number
   */
  get cceGroupsListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_CCE_GROUPS](data) {
    this.cceGroups = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_CCE_GROUPS](data) {
    this.cceAllGroups = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_CCE_QUALITY](data) {
    this.cceAllQuality = data.data;
  }

  @Mutation
  [Mutations.SET_CCE_GROUP_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_CCE_GROUPS_LIST]() {
    this.cceGroups = [] as Array<CceGroupObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_CCE_GROUPS_STORE]() {
    this.context.commit(Mutations.RESET_CCE_GROUPS_LIST);
  }

  @Action
  [Actions.CREATE_CCE_GROUP](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/cce-groups', data)
      .then(() => {
        this.context.commit(Mutations.RESET_CCE_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_CCE_GROUP](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/cce-groups', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CCE_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_CCE_GROUP]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/cce-groups', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_CCE_GROUPS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_CCE_GROUP_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_CCE_GROUP_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_CCE_GROUP](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/cce-groups', id);
  }

  @Action
  [Actions.GET_CCE_GROUPS](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: options.limit };
    return ApiService.query('/cce-groups', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CCE_GROUPS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_ALL_CCE_GROUPS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/cce-groups/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_CCE_GROUPS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_ALL_CCE_QUALITY]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/cce-groups/cce-quality/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_CCE_QUALITY, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
