import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface IreInvestmentConfigObject {
  id: number;
  title: string;
  percentage: number;
}
export interface IreInvestmentFactorsConfigObject {
  ireInvestmentFactorOption: string;
  value: string;
  fOptionId: number;
}

export interface IreInvestmentFactorValuesObject {
  typeId: number;
  ireInvestmentFactorsConfig: Array<IreInvestmentFactorsConfigObject>;
}

export interface FactorOptionObject {
  ireInvestmentFactorOption: string;
  value: string;
  fOptionId: number;
  id: number;
  title: string;
}

export interface IreInvestmentFactorsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  ireInvestmentConfig: Array<IreInvestmentConfigObject>;
  errors: unknown;
}

@Module
export default class IreInvestmentFactorssModule
  extends VuexModule
  implements IreInvestmentFactorsListInfo
{
  ireInvestmentSizes = [] as Array<any>; // ddl for size
  ireInvestmentPropertysAge = [] as Array<any>; // ddl for property age
  ireInvestmentSizesDesc = [] as Array<any>; // ddl for size Desc
  ireInvestmentConfig = [] as Array<IreInvestmentConfigObject>;
  ireInvestmentPropertyTypes = [] as Array<any>;
  ireInvestmentPropertyAge = [] as Array<any>;
  ireInvestmentPropertyLocations = [] as Array<any>;
  ireInvestmentQualities = [] as Array<any>;

  ireInvestmentFactorsAreasValues =
    [] as Array<IreInvestmentFactorValuesObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};
  factorType = '';

  /**
   * Get ireInvestmentConfig list
   * @returns Array<ireInvestmentConfigObject>
   */
  get ireInvestmentConfigList(): Array<IreInvestmentConfigObject> {
    return this.ireInvestmentConfig;
  }

  /**
   * Get total items
   * @returns number
   */
  get ireInvestmentConfigListCount(): number {
    return this.totalItems;
  }
  get ireInvestmentFactorsAreasValuesList(): Array<IreInvestmentFactorValuesObject> {
    return this.ireInvestmentFactorsAreasValues;
  }
  get getireInvestmentPropertyTypes(): Array<any> {
    return this.ireInvestmentPropertyTypes;
  } // ddl for size

  get getireInvestmentPropertyAge(): Array<any> {
    return this.ireInvestmentPropertysAge;
  } // ddl for property age

  get getireInvestmentPropertyLocations(): Array<any> {
    return this.ireInvestmentPropertyLocations;
  } // ddl for size desc

  get getireInvestmentQualities(): Array<any> {
    return this.ireInvestmentQualities;
  } // ddl for size desc

  @Mutation
  [Mutations.SET_IRE_CONFIGS](data) {
    this.ireInvestmentConfig = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_INVESTMENT_PROPERTY_TYPE](data) {
    this.ireInvestmentPropertyTypes = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_INVESTMENT_PROPERTY_AGE](data) {
    this.ireInvestmentPropertysAge = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_INVESTMENT_PROPERTY_LOCATIONS](data) {
    this.ireInvestmentPropertyLocations = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_INVESTMENT_QUALITIES](data) {
    this.ireInvestmentQualities = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_IRE_V2_FACTOR_AREAS_VALUES](data) {
    this.ireInvestmentFactorsAreasValues = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_IRE_V2_SIZE](data) {
    this.ireInvestmentSizes = data.data;
  }

  // @Mutation
  // [Mutations.SET_ALL_IRE_V2_PROPERTY_AGE](data) {
  //   this.ireInvestmentPropertysAge = data.data;
  // }

  @Mutation
  [Mutations.SET_ALL_IRE_V2_SIZE_DESC](data) {
    this.ireInvestmentSizesDesc = data.data;
  }

  @Mutation
  [Mutations.SET_IRE_CONFIG_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_IRE_CONFIG_LIST]() {
    this.ireInvestmentConfig = [] as Array<IreInvestmentConfigObject>;
    this.ireInvestmentPropertyTypes = [] as Array<any>;
    this.ireInvestmentPropertyAge = [] as Array<any>;
    this.ireInvestmentPropertyLocations = [] as Array<any>;
    this.ireInvestmentQualities = [] as Array<any>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.UPDATE_IRE_CONFIG]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ire-investment-config', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_CONFIG_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_IRE_CONFIG_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_IRE_CONFIG_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_IRE_CONFIG](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment-config', id);
  }

  @Action
  [Actions.GET_IRE_CONFIGS](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      limit: options.limit,
    };
    return ApiService.query('/ire-investment-config', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_CONFIGS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_IRE_INVESTMENT_PROPERTY_TYPES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment-config/property-types')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_INVESTMENT_PROPERTY_TYPE, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_IRE_INVESTMENT_PROPERTY_AGES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment-config/property-age')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_INVESTMENT_PROPERTY_AGE, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_IRE_INVESTMENT_PROPERTY_LOCATIONS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment-config/locations')
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_IRE_INVESTMENT_PROPERTY_LOCATIONS,
          data
        );
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_IRE_INVESTMENT_QUALITIES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ire-investment-config/qualities')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IRE_INVESTMENT_QUALITIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.UPDATE_IRE_ENUMS]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update(
      `/ire-investment-config/${data.endPoint}`,
      id,
      data
    )
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_CONFIG_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_IRE_ENUM](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/ire-investment-config/${id.endPoint}`, id.id);
  }
  @Action
  [Actions.DELETE_IRE_INVESTMENT_PROPERTY_TYPES](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ire-investment-config/property-types', id)
      .then(() => {
        this.context.commit(Mutations.RESET_IRE_CONFIG_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
