import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface Auction {
  guideVideo: string;
  eventDateTime: string;
  venueEn: string;
  venueAr: string;
  descriptionEn: string;
  descriptionAr: string;
  sizes: string;
  additionalInfoEn: string;
  additionalInfoAr: string;
  organiserName: string;
  venue: string;
  id: number;
  organiserId: number;
  propertiesListedNum: number;
  propertiesSoldNum: number;
  propertiesNotSoldNum: number;
  totalSale: number;
  auctionPropertysCount: number;
  organisersLocationsId: number | null;
  latitude: number | null;
  longitude: number | null;
  expiryBanner: string;
}

export interface Property {
  id: number;
  propertyId: number;
  block: number;
  plot: number;
  size: number;
  startingPrice: number;
  startingPricePerSqrM: number;
  statusId: number | null;
  salePrice: number;
  salePricePerSqrM: number;
  percentagePremiumPaid: number;
  isActive: boolean;
  additionalInfoEn: string;
  additionalInfoAr: string;
  createdAt: string;
  areaName: number | null;
}

export interface AuctionsFilterListInfo {
  type: string;
  dateFrom: string;
  dateTo: string;
  organiserId: undefined | number | '';
  areaId: undefined | number | '';
  size: undefined | number;
  startingPrice: undefined | number;
  salePrice: undefined | number;
  auctionStatus: undefined | number | '';
  propertyTypeId: undefined | number | '';
}

export interface AuctionStatus {
  id: number;
  title: string;
  isActive: boolean;
}
export interface AuctionOrganasie {
  id: number;
  titleEn: string;
  titleAr: string;
  phone: string;
  is_active: boolean;
  logo: string;
}

export interface AuctionsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  type: string;
  isNext: boolean;
  previous: number;
  auctions: Array<Auction>;
  errors: unknown;
}

@Module
export default class AuctionsModule
  extends VuexModule
  implements AuctionsListInfo
{
  auctions = [] as Array<Auction>;
  auctionProperties = [] as Array<Property>;
  auctionStatuses = [] as Array<AuctionStatus>;
  auctionOrganises = [] as Array<AuctionOrganasie>;
  auctionFilter = {} as AuctionsFilterListInfo;
  totalItems = 0;
  currentPage = 1;
  type = '';
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get auctions list
   * @returns Array<Auction>
   */
  get auctionsList(): Array<Auction> {
    debugger;
    if (this.auctions.length > 0) this.auctions[0]['page'] = this.currentPage;
    return this.auctions;
  }

  /**
   * Get total items
   * @returns number
   */
  get auctionsListCount(): number {
    return this.totalItems;
  }

  /**
   * Get auction properties list
   * @returns array
   */
  get auctionPropertiesList(): Array<Property> {
    return this.auctionProperties;
  }

  /**
   * Get auction statuses list
   * @returns array
   */
  get auctionStatusesList(): Array<AuctionStatus> {
    return this.auctionStatuses;
  }

  get auctionOrganisesList(): Array<AuctionOrganasie> {
    return this.auctionOrganises;
  }

  @Mutation
  [Mutations.SET_AUCTIONS](data) {
    this.auctions = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AUCTION_PROPERTIES](data) {
    this.auctionProperties = data.data;
  }

  @Mutation
  [Mutations.SET_AUCTION_STATUSES](data) {
    this.auctionStatuses = data.data;
  }

  @Mutation
  [Mutations.SET_AUCTION_ORGANISE](data) {
    this.auctionOrganises = data.data;
  }

  @Mutation
  [Mutations.SET_AUCTIONS_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_AUCTIONS_LIST]() {
    this.auctions = [] as Array<Auction>;
    this.type = '';
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.RESET_AUCTIONS_PROPERTY]() {
    this.auctionProperties = [] as Array<Property>;
  }

  @Mutation
  [Mutations.SET_AUCTIONS_FILTER](obj) {
    this.auctionFilter.dateFrom = String(obj.dateFrom);
    this.auctionFilter.dateTo = String(obj.dateTo);
    this.auctionFilter.areaId = Number(obj.areaId);
    this.auctionFilter.propertyTypeId = Number(obj.propertyTypeId);
    this.auctionFilter.organiserId = Number(obj.organiserId);
    this.auctionFilter.auctionStatus = Number(obj.auctionStatus);
    this.auctionFilter.size = Number(obj.size);
    this.auctionFilter.startingPrice = Number(obj.startingPrice);
    this.auctionFilter.salePrice = Number(obj.salePrice);
    this.auctionFilter.type = String(obj.type);
  }

  @Action
  [Actions.RESET_AUCTIONS_STORE]() {
    this.context.commit(Mutations.RESET_AUCTIONS_LIST);
  }

  @Action
  [Actions.RESET_AUCTIONS_PROPERTYS]() {
    this.context.commit(Mutations.RESET_AUCTIONS_PROPERTY);
  }

  @Action
  [Actions.UPDATE_AUCTIONS_FILTER](val) {
    this.context.commit(Mutations.SET_AUCTIONS_FILTER, val);
  }

  @Action
  [Actions.DELETE_AUCTION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('auctions', id)
      .then(() => {
        this.context.commit(Mutations.RESET_AUCTIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_AUCTION_PROPERTY](id) {
    ApiService.setAuthorizationHeader();
    return (
      ApiService.delete('auctions/properties', id)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {})
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {
            [response.statusText]: [response.data.message],
          });
        })
    );
  }

  @Action
  [Actions.CREATE_AUCTION](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/auctions', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_AUCTIONS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.CREATE_AUCTION_PROPERTY]({ data, auctionId }) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/auctions/properties/${auctionId}`, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_AUCTIONS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_AUCTION_PROPERTY]({ data, propertyId }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/auctions/properties', propertyId, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_AUCTIONS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPLOAD_AUCTION_VIDEO]({ data, auctionId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/auctions/uploadVideo/${auctionId}`, data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET_AUCTIONS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPLOAD_AUCTION_ATTACHMENTS]({ data, auctionId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/auctions/uploadAttachment/${auctionId}`, data)
      .then(({ data }) => {
        // this.context.commit(Mutations.RESET_AUCTIONS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_AUCTION]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/auctions', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_AUCTIONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_AUCTION_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_AUCTIONS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_AUCTION](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('auctions', id);
  }

  @Action
  [Actions.GET_ALL_AUCTION_STATUSES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/auctions-assets/auctions-status/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUCTION_STATUSES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_AUCTION_ORGANISERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/auctions-organisers/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUCTION_ORGANISE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_AUCTION_PROPERTIES](auctionId) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/auctions/properties/events/${auctionId}`)
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });

        this.context.commit(Mutations.SET_AUCTION_PROPERTIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_AUCTION_PROPERTY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/auctions/properties', id);
  }

  @Action
  [Actions.GET_AUCTIONS]() {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      dateFrom: this.auctionFilter.dateFrom,
      dateTo: this.auctionFilter.dateTo,
      areaId: this.auctionFilter.areaId,
      startingPrice: this.auctionFilter.startingPrice,
      salePrice: this.auctionFilter.salePrice,
      organiserId: this.auctionFilter.organiserId,
      propertyTypeId: this.auctionFilter.propertyTypeId,
      size: this.auctionFilter.size,
      auctionStatus: this.auctionFilter.auctionStatus,
      type: this.auctionFilter.type ? this.auctionFilter.type : '',
    };
    return ApiService.query('/auctions', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          const enDate = new Date(r.eventDateTime).toLocaleString('en-GB');
          r.eventDate = enDate.split(',')[0];
          r.eventTime = enDate.split(',')[1];
          return r;
        });
        this.context.commit(Mutations.SET_AUCTIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.CLONE_AUCTION_PROPERTY](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/auctions/create-clone', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_AUCTIONS_PROPERTY);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.COPY_AUCTION_PROPERTY](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/auctions/swap-clone', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_AUCTIONS_PROPERTY);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
