import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface FaqObject {
  id: number;
  questionEn: string;
  questionAr: string;
  answerEn: string;
  answerAr: string;
  questionEnShort: string;
  questionArShort: string;
  answerEnShort: string;
  answerArShort: string;
  isActive: boolean;
}

export interface FaqListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  faqs: Array<FaqObject>;
  errors: unknown;
}

@Module
export default class FaqsModule extends VuexModule implements FaqListInfo {
  faqs = [] as Array<FaqObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get faqs list
   * @returns Array<FaqObject>
   */
  get faqsList(): Array<FaqObject> {
    return this.faqs;
  }

  /**
   * Get total items
   * @returns number
   */
  get faqsListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_FAQS](data) {
    this.faqs = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_FAQ_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_FAQS_LIST]() {
    this.faqs = [] as Array<FaqObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_FAQ](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/settings/faq', data)
      .then(() => {
        this.context.commit(Mutations.RESET_FAQS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_FAQ](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/settings/faq', id)
      .then(() => {
        this.context.commit(Mutations.RESET_FAQS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_FAQ]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/settings/faq', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_FAQS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_FAQ_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_FAQ_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_FAQ](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/settings/faq', id);
  }

  @Action
  [Actions.GET_FAQS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/settings/faq', {
      params: { page: this.currentPage },
    })
      .then(({ data }) => {
        data.data = data.data.map((m) => {
          m.questionArShort =
            m.questionAr.length > 50
              ? `. . . ${m.questionAr.slice(0, 50)}`
              : m.questionAr;
          m.questionEnShort =
            m.questionEn.length > 50
              ? `${m.questionEn.slice(0, 50)} . . .`
              : m.questionEn;
          m.answerArShort =
            m.answerAr.length > 50
              ? `. . . ${m.answerAr.slice(0, 50)}`
              : m.answerAr;
          m.answerEnShort =
            m.answerEn.length > 50
              ? `${m.answerEn.slice(0, 50)} . . .`
              : m.answerEn;
          return m;
        });
        this.context.commit(Mutations.SET_FAQS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
}
