import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface OrganizerObject {
  id: number;
  titleEn: string;
  titleAr: string;
  logo: string;
  phone: string;
  is_active: boolean;
}

export interface OrganizersListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  organizers: Array<OrganizerObject>;
  errors: unknown;
}

@Module
export default class OrganizersModule
  extends VuexModule
  implements OrganizersListInfo
{
  organizers = [] as Array<OrganizerObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get organizers list
   * @returns Array<OrganizerObject>
   */
  get organizersList(): Array<OrganizerObject> {
    return this.organizers;
  }

  /**
   * Get total items
   * @returns number
   */
  get organizersListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_ORGANIZERS](data) {
    this.organizers = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ORGANIZER_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_ORGANIZERS_LIST]() {
    this.organizers = [] as Array<OrganizerObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_ORGANIZERS_STORE]() {
    this.context.commit(Mutations.RESET_ORGANIZERS_LIST);
  }

  @Action
  [Actions.CREATE_ORGANIZER](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/auctions-organisers', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_ORGANIZERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_ORGANIZER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/auctions-organisers', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ORGANIZERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_ORGANIZER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/auctions-organisers', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ORGANIZERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_ORGANIZER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_ORGANIZER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ORGANIZER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/auctions-organisers', id);
  }

  @Action
  [Actions.GET_ORGANIZERS](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: options.limit };
    return ApiService.query('/auctions-organisers', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORGANIZERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.UPLOAD_ORGANIZER_LOGO]({ data, organizerId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(
      `/auctions-organisers/uploadImage/${organizerId}`,
      data
    )
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
